import React, { useContext, useEffect, useState } from "react";
import { TeamsFxContext } from "../Context";
import useAxios from "../lib/useAxios";
import { removeFromLocalStore } from "../lib/localStorage";
import { getMainRole } from "../lib/common";
import { TechnicianScreen } from "../screens/TechnicianScreen";
import { RequesterScreen } from "../screens/RequesterScreen";
import { AdminScreen } from "../screens/AdminScreen";

const roleComponents = {
  requester: RequesterScreen,
  technician: TechnicianScreen,
  admin: AdminScreen,
};

export function UserView() {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { authenticate, isUserAuthenticated } = useAxios(organizationApiUrl);
  const [loading, setLoading] = useState(true);
  const [mainRole, setMainRole] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const isAlreadyAuthenticated = await isUserAuthenticated();
        if (!isAlreadyAuthenticated) {
          // clear localstore in case the user change its session
          removeFromLocalStore(["__atkn", "__incif", "__assigf"]);
          await authenticate();
        }
        setMainRole(getMainRole());
      } catch (e) {
        console.debug(e);
      } finally {
        setLoading(false);
      }
    };
    checkAuthentication();
  }, [authenticate, isUserAuthenticated]);

  if (loading) {
    return <></>;
  }

  const RoleComponent = roleComponents[mainRole];
  return RoleComponent ? <RoleComponent /> : null;
}
