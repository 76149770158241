import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Title3,
  Body1,
  Button,
  Caption1,
  Field,
  Input,
  Label,
  Dropdown,
  Option,
  Checkbox,
  Combobox,
  Tag,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Spinner,
} from "@fluentui/react-components";
import { DeleteRegular, AddSquareRegular, PeopleCommunity16Regular } from "@fluentui/react-icons";
import { authentication } from "@microsoft/teams-js";
import config from "../../../lib/config";
import useAxios from "../../../lib/useAxios";
import { debounce } from "lodash";
import { generateRandomId, getTenantIdFromMstToken } from "../../../lib/common";
import { TeamsFxContext } from "../../../Context";
import Announcement from "../../../components/Announcement";
import { MessageGroup } from "../../../components/MessageGroup";

export const Signup = () => {
  const { teamsUserCredential, updateOrganizationStatus, updateOrganizationName } =
    useContext(TeamsFxContext);
  const [needConsent, setNeedConsent] = useState(false);
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);
  const { axiosInstance: axiosPublicInstance } = useAxios(config.publicCloudEndpoint, false);

  const steps = [
    "Organization Details",
    "Incident Categories",
    "Technician Teams",
    "Review and Confirm",
  ];
  const [currentStep, setCurrentStep] = useState(-1);

  const [loading, setLoading] = useState(false);
  const [errorBackendMessages, setErrorBackendMessages] = useState([]);

  const [ssoToken, setSsoToken] = useState();
  const [organizationName, setOrganizationName] = useState(); //---Send to backend
  const [showErrorOrganizationName, setShowErrorOrganizationName] = useState(false);

  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(); //---Send to backend
  const [showErrorIndustrySelected, setShowErrorIndustrySelected] = useState(false);

  const [selectedCategories, setSelectedCategories] = useState([]); //---Send to backend
  const [noCategoryIsSelected, setNoCategoryIsSelected] = useState(false);
  const [industryProfiles, setIndustryProfiles] = useState([]); //--From selected industry

  const [organizationAdmin, setOrganizationAdmin] = useState();
  const [organizationTenantId, setOrganizationTenantId] = useState();
  const [organizationUsers, setOrganizationUsers] = useState();
  const [teamNamePlaceholder, setTeamNamePlaceholder] = useState("");
  const [newTeams, setNewTeams] = useState([{ name: "", profiles: [], teammates: [] }]); //---Send to backend
  const [isValidatingTeams, setIsvalidatingTeams] = useState(false);

  useEffect(() => {
    const pullIndustries = async () => {
      try {
        const { data } = await axiosHubInstance.get("/catalogs/industries");
        setIndustries(data);
      } catch (err) {
        console.debug(err);
      }
    };

    const initialOrgSetup = async () => {
      if (!teamsUserCredential) {
        throw new Error("TeamsFx SDK is not initialized.");
      }
      if (needConsent) {
        await teamsUserCredential.login([
          "User.Read",
          "User.ReadBasic.All",
          "Directory.Read.All",
          "Sites.ReadWrite.All",
          "TeamsActivity.Send",
        ]);
        setNeedConsent(false);
      }
      try {
        const sso_token = await authentication.getAuthToken();
        setSsoToken(sso_token);
        /* Organization Administrator */
        const { data: currentUser } = await axiosPublicInstance.get("/msteams/delegated/user", {
          headers: {
            authorization: sso_token,
          },
        });
        setOrganizationAdmin(currentUser);
        setOrganizationTenantId(getTenantIdFromMstToken(sso_token));
      } catch (error) {
        if (error.message.includes("The application may not be authorized.")) {
          setNeedConsent(true);
        }
      }
    };

    initialOrgSetup();
    pullIndustries();
  }, []);

  const getOrganizationUsers = debounce(async (contain, index) => {
    try {
      const toBeExcluded = newTeams[index].teammates;

      const updatedTeams = [...newTeams];
      updatedTeams[index] = { ...updatedTeams[index], loading: true };
      setNewTeams(updatedTeams);

      const { data: orgUsers } = await axiosPublicInstance.get(
        `/msteams/delegated/users?contain=${contain}`,
        {
          headers: {
            authorization: ssoToken,
          },
        }
      );
      const display = orgUsers.filter((u) => !toBeExcluded.map((e) => e.email).includes(u.email));
      setOrganizationUsers(display);
    } catch (error) {
      if (error.message.includes("status code 401")) {
        const sso_token = await authentication.getAuthToken();
        setSsoToken(sso_token);
      }
    } finally {
      const updatedTeams = [...newTeams];
      updatedTeams[index] = { ...updatedTeams[index], loading: undefined };
      setNewTeams(updatedTeams);
    }
  }, 500);

  const handleContinueClick = async () => {
    let canContinue = true;
    if (currentStep === 0) {
      // Organization Details
      if (organizationName === "" || organizationName === undefined) {
        setShowErrorOrganizationName(true);
        canContinue = false;
      }
      if (selectedIndustry === undefined) {
        setShowErrorIndustrySelected(true);
        canContinue = false;
      }
    } else if (currentStep === 1) {
      configureTeamsProfiles();
    } else if (currentStep === 2) {
      setIsvalidatingTeams(true);
      const haveSomeInvalidTeam = newTeams.find(
        (team) => team.name === "" || team.profiles.length === 0 || team.teammates.length === 0
      );
      if (haveSomeInvalidTeam) {
        return;
      }
    } else if (currentStep === 3) {
      try {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        const organization = {
          name: organizationName,
          org_admin: organizationAdmin,
          ms_tenant_id: organizationTenantId,
          industry_code: selectedIndustry.code,
          industry_name: selectedIndustry.name,
          categories: selectedCategories,
          teams: newTeams.map((t) => ({
            name: t.name,
            profiles: t.profiles,
            teammates: t.teammates.map((m) => ({
              display_name: m.display_name,
              email: m.email,
              ms_id: m.ms_id,
              job_title: m.job_title,
            })),
          })),
          timezone_default_location: timeZone,
          recaptcha_token: "grecaptchaToken",
        };
        setLoading(true);
        const { status, data } = await axiosHubInstance.post("/org/onboarding/teams", organization);
        if (status === 200) {
          updateOrganizationStatus("active", data.individual_api_url);
          updateOrganizationName(organizationName);
        }
      } catch (e) {
        setErrorBackendMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and your Organization could not be registered. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) =>
              setErrorBackendMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
          },
          ...prevMessages,
        ]);
      } finally {
        setLoading(false);
        return;
      }
    }
    if (canContinue) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBackClick = () => {
    setCurrentStep(currentStep - 1);
  };

  //---Industry---
  const handleIndustrySelected = (event, data) => {
    const selectedCode = data.optionValue;
    const selectedIndustry = industries.find((industry) => industry.code === selectedCode);
    setSelectedIndustry(selectedIndustry);
    setSelectedCategories([]);
    if (noCategoryIsSelected) setNoCategoryIsSelected(false);
    //Delete selected profiles
    const updatedTeams = newTeams.map((team) => ({
      name: team.name,
      profiles: [],
      teammates: [],
    }));
    setNewTeams(updatedTeams);
    // Hide errors if exist
    if (showErrorIndustrySelected) {
      setShowErrorIndustrySelected(false);
    }
  };

  //---categories---

  const onCheckboxCategoryChange = (e, opt) => {
    let selectedCategory = [...selectedCategories];
    if (opt.checked) {
      selectedCategory.push(Number(e.target.value));
    } else {
      selectedCategory.splice(selectedCategory.indexOf(Number(e.target.value)), 1);
    }
    setSelectedCategories(selectedCategory);
    if (noCategoryIsSelected) setNoCategoryIsSelected(false);
  };

  const onCheckboxNoCategoryChange = (e, opt) => {
    setNoCategoryIsSelected(opt.checked);
    if (selectedCategories.length > 0) setSelectedCategories([]);
  };

  //---teams---

  const configureTeamsProfiles = async () => {
    try {
      const url = new URL("/catalogs/industries/profiles", "http://my.com");
      selectedCategories.forEach((id) => url.searchParams.append("category_ids", id));
      const { data } = await axiosHubInstance.get(`${url.pathname}${url.search}`);
      if (data.length > 0) {
        setTeamNamePlaceholder(`e.g., ${data[0].suggestion}`);
      }
      setIndustryProfiles(data);
      // Check for previous teams
      if (newTeams.filter((t) => t.profiles.length > 0)) {
        const updatedTeams = newTeams.map((team) => ({
          name: team.name,
          profiles: [],
          teammates: [],
        }));
        setNewTeams(updatedTeams);
      }
    } catch (err) {
      console.debug(err);
    }
  };

  const onAddNewTeamAction = () => {
    setIsvalidatingTeams(false);
    setNewTeams([...newTeams, { name: "", profiles: [], teammates: [] }]);
  };

  const onRemoveTeamAction = (index) => {
    const updatedTeams = [...newTeams.slice(0, index), ...newTeams.slice(index + 1)];
    setNewTeams(updatedTeams);
  };

  const onTeamNameChange = (value, index) => {
    const updatedTeams = [...newTeams];
    updatedTeams[index] = { ...updatedTeams[index], name: value };
    setNewTeams(updatedTeams);
    /* if (e.target.value !== "" && showErrorOrganizationName) {
        setShowErrorOrganizationName(false);
      } */
  };

  const onTeamProfileChange = (profiles, index) => {
    const updatedTeams = [...newTeams];
    updatedTeams[index] = {
      ...updatedTeams[index],
      profiles: profiles,
    };
    setNewTeams(updatedTeams);
  };

  const onTeamTeammateSelect = (teammates, index) => {
    const updatedTeams = [...newTeams];
    updatedTeams[index] = {
      ...updatedTeams[index],
      teammates: teammates,
    };
    setNewTeams(updatedTeams);
  };

  //------------

  return (
    <div className="layout-page-wizard">
      {currentStep === -1 ? (
        <div className="wizard-welcome">
          <Announcement
            imgSrc="welcome_config.png"
            imgAlt="Welcome"
            imgWidth="15em"
            title="Welcome to Resolve Central"
            subtitle={
              <div style={{ textAlign: "left" }}>
                <p>
                  Let's set up Resolve Central for your organization. By continuing, you confirm
                  that you:
                </p>
                <ol>
                  <li>Are an administrator of the organization</li>
                  <li>Have the necessary permissions to manage Resolve Central</li>
                </ol>

                <p>Ready to begin?</p>
              </div>
            }
            //"Let's start by configuring Resolve Central for your organization. By continuing I confirm that I am an administrator of the organization and have the necessary permissions to manage Resolve Central."
            actionComponent={
              <Button appearance="primary" onClick={handleContinueClick}>
                Get Started
              </Button>
            }
          />
        </div>
      ) : (
        <>
          <div className="wizard-sidebar">
            <ul>
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={
                    index === currentStep ? "current" : index < currentStep ? "checked" : "pending"
                  }
                >
                  {step}
                </li>
              ))}
            </ul>
          </div>
          <div className="wizard-wrapper">
            <div className="ml-4" style={{ width: "50%" }}>
              <MessageGroup messages={errorBackendMessages} />
            </div>
            <div className={currentStep === 0 ? "wizard-container" : "hidden"}>
              <Title3>Organization Details</Title3>
              <Body1 className="wizard-subtitle">
                Enter your organization's name and select your industry
              </Body1>

              <div className="wizard-form">
                <Field
                  label="Organization Name"
                  validationMessage={
                    showErrorOrganizationName ? "Provide an organization name" : ""
                  }
                >
                  <Input
                    placeholder="Organization Name"
                    onChange={(e) => {
                      setOrganizationName(e.target.value);
                      if (e.target.value !== "" && showErrorOrganizationName) {
                        setShowErrorOrganizationName(false);
                      }
                    }}
                  />
                </Field>
                <Field
                  label="Industry"
                  validationMessage={
                    showErrorIndustrySelected
                      ? "Select the industry related to your organization"
                      : ""
                  }
                >
                  <Dropdown
                    placeholder="Select an industry"
                    onOptionSelect={handleIndustrySelected}
                  >
                    {industries.map((industry) => (
                      <Option key={industry.code} value={industry.code}>
                        {industry.name}
                      </Option>
                    ))}
                  </Dropdown>
                </Field>
              </div>
            </div>
            <div className={currentStep === 1 ? "wizard-container" : "hidden"}>
              <Title3>Incident Categories</Title3>
              <Body1 className="wizard-subtitle">
                Select the types of incidents your organization will manage
              </Body1>

              {selectedIndustry &&
                selectedIndustry.categories.map((category, index) => {
                  return (
                    <Checkbox
                      key={index}
                      inputId={category.id}
                      name="option"
                      size="large"
                      value={category.id}
                      checked={selectedCategories.indexOf(category.id) !== -1}
                      onChange={onCheckboxCategoryChange}
                      label={
                        <>
                          <b>{category.name}</b>
                          <Label htmlFor={category.id}>
                            {category.description && (
                              <p className="checkbox-label">{category.description}</p>
                            )}
                          </Label>
                        </>
                      }
                    />
                  );
                })}

              <Checkbox
                inputId="None"
                name="option"
                size="large"
                value="None"
                label={<b>None of the above</b>}
                checked={noCategoryIsSelected}
                onChange={onCheckboxNoCategoryChange}
              />
              {noCategoryIsSelected && (
                <div style={{ marginTop: "1em", width: "50%" }}>
                  <MessageGroup
                    animate={true}
                    messages={[
                      {
                        id: "1111",
                        intent: "warning",
                        title: "Attention:",
                        body: "If you couldn't find an incident category that fits your organization's needs, try going back and selecting another industry or contact us at contact@knbases.com to review your categories.",
                      },
                    ]}
                  />
                </div>
              )}
            </div>
            <div className={currentStep === 2 ? "wizard-container" : "hidden"}>
              <Title3>Technician Teams</Title3>
              <Body1 className="wizard-subtitle">
                Add your first Technician Teams below to start managing requests. You can modify
                these teams anytime through the admin settings.
              </Body1>

              {newTeams.map((team, index) => {
                return (
                  <div
                    style={{
                      columnGap: "15px",
                      display: "flex",
                      alignItems: "flex-start",
                      marginBottom: "15px",
                    }}
                    key={index}
                  >
                    <Field
                      label="Team's name"
                      style={{ minWidth: 200 }}
                      validationMessage={
                        isValidatingTeams && team.name === "" ? "Provide a name for this Team" : ""
                      }
                    >
                      <Input
                        placeholder={teamNamePlaceholder}
                        value={team.name}
                        onChange={(e) => onTeamNameChange(e.target.value, index)}
                      />
                    </Field>

                    <Field
                      label="Profiles"
                      style={{ maxWidth: 400 }}
                      validationMessage={
                        isValidatingTeams && team.profiles.length === 0
                          ? "Select some profiles for this Team"
                          : ""
                      }
                    >
                      <Combobox
                        multiselect
                        placeholder="Select profiles"
                        selectedOptions={team.profiles}
                        readOnly
                        value={newTeams[index] ? newTeams[index].profiles.join(", ") : ""}
                        onOptionSelect={(e, data) =>
                          onTeamProfileChange(data.selectedOptions, index)
                        }
                      >
                        {industryProfiles.map((option) => (
                          <div key={option.profile_category}>
                            <span className="menu-subtitle">{option.profile_category}</span>
                            {option.profiles.map((profile) => (
                              <Option key={profile}>{profile}</Option>
                            ))}
                          </div>
                        ))}
                      </Combobox>
                    </Field>
                    <Field
                      label="Teammates"
                      style={{ maxWidth: 400 }}
                      validationMessage={
                        isValidatingTeams && team.teammates.length === 0
                          ? "Select some teammates for this Team"
                          : ""
                      }
                    >
                      <TagPicker
                        onOptionSelect={(e, data) =>
                          onTeamTeammateSelect(data.selectedOptions, index)
                        }
                        selectedOptions={
                          newTeams[index] && newTeams[index].teammates
                            ? newTeams[index].teammates
                            : []
                        }
                      >
                        <TagPickerControl
                          expandIcon={newTeams[index].loading ? <Spinner size="tiny" /> : null}
                        >
                          <TagPickerGroup>
                            {newTeams[index] &&
                              newTeams[index].teammates &&
                              newTeams[index].teammates.map((teammate) => (
                                <Tag
                                  key={teammate.email}
                                  shape="circular"
                                  media={
                                    <Avatar
                                      aria-hidden
                                      name={teammate.display_name}
                                      color="colorful"
                                    />
                                  }
                                  value={teammate}
                                >
                                  {teammate.display_name}
                                </Tag>
                              ))}
                          </TagPickerGroup>
                          <TagPickerInput
                            aria-label="Enter teammate's name"
                            placeholder={
                              newTeams[index] && newTeams[index].teammates.length > 0
                                ? ""
                                : "Enter teammate's name"
                            }
                            onChange={(e) => getOrganizationUsers(e.target.value, index)}
                          />
                        </TagPickerControl>
                        <TagPickerList>
                          {organizationUsers &&
                          organizationUsers.filter(
                            (u) => !newTeams[index].teammates.map((t) => t.email).includes(u.email)
                          ).length > 0
                            ? organizationUsers
                                .filter(
                                  (u) =>
                                    !newTeams[index].teammates.map((t) => t.email).includes(u.email)
                                )
                                .map((user) => (
                                  <TagPickerOption
                                    media={
                                      <Avatar
                                        shape="circular"
                                        aria-hidden
                                        name={user.display_name}
                                        color="colorful"
                                      />
                                    }
                                    value={user}
                                    key={user.email}
                                  >
                                    {user.display_name}
                                  </TagPickerOption>
                                ))
                            : "Enter teammate's name"}
                        </TagPickerList>
                      </TagPicker>
                    </Field>
                    {index > 0 && (
                      <Button
                        appearance="subtle"
                        icon={
                          <DeleteRegular style={{ color: "var(--colorPaletteRedForeground1)" }} />
                        }
                        style={{ marginTop: "26px" }}
                        onClick={() => onRemoveTeamAction(index)}
                      />
                    )}
                  </div>
                );
              })}

              <Button
                appearance="transparent"
                icon={<AddSquareRegular />}
                onClick={onAddNewTeamAction}
              >
                Add another team
              </Button>
            </div>
            <div className={currentStep === 3 ? "wizard-container" : "hidden"}>
              <Title3>Review and Confirm</Title3>
              <Body1 className="wizard-subtitle">
                Review your configuration below. No worries - you can always update these settings
                later
              </Body1>

              <div className="p-fluid mt-5">
                <h3>Industry</h3>
                <p>{selectedIndustry?.name}</p>

                <h3>Incident Categories</h3>
                <ul>
                  {selectedCategories.map((categoryId) => (
                    <li key={categoryId}>
                      {selectedIndustry.categories.find((cat) => cat.id === categoryId)?.name}
                    </li>
                  ))}
                </ul>

                <h3>Technician Teams</h3>
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {newTeams.map((t, index) => (
                    <li key={index} className="mb-4">
                      <div className="icon-container mb-4">
                        <PeopleCommunity16Regular className="mr-2" />
                        <span className="font-bold">{t.name}</span>
                      </div>
                      <div className="ml-4 mb-4">
                        <div className="flex-column mb-4">
                          <Caption1>Profiles:</Caption1>
                        </div>
                        <div style={{ width: "40%" }}>
                          {t.profiles.map((p) => (
                            <Tag
                              key={p}
                              size="small"
                              shape="circular"
                              className="mr-2"
                              style={{ marginBottom: "5px" }}
                            >
                              {p}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="wizard-bottom-bar">
              <div className="btn-container">
                {currentStep > 0 && (
                  <Button style={{ marginRight: "1em" }} onClick={handleBackClick}>
                    Back
                  </Button>
                )}
                <Button
                  appearance="primary"
                  onClick={handleContinueClick}
                  disabled={(currentStep === 1 && selectedCategories.length === 0) || loading}
                >
                  {currentStep < 3 ? "Continue" : "Confirm and Begin"}
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
