import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  Button,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import Timeline from "../../../components/Timeline";

export const HistoryDialog = ({ isOpen, history, onClose }) => {
  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle style={{ justifyContent: "space-between", display: "flex" }}>
            <span>History</span>
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={onClose}
            />
          </DialogTitle>
          <DialogContent className="scrollable-content" style={{ minHeight: "500px" }}>
            <Timeline history={history} />
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
