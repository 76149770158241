import { TagGroup, Link, Tooltip } from "@fluentui/react-components";
import { Attach16Filled } from "@fluentui/react-icons";
import { app } from "@microsoft/teams-js";
import { shortenFileName } from "../../lib/common";

export function CompactAttachmentList({ list }) {
  return (
    <TagGroup size="small" style={{ paddingBlock: "5px", flexWrap: "wrap" }}>
      {list.map(({ fileName, url }) => (
        <Tooltip withArrow content={fileName} showDelay={1000}>
          <Link key={fileName} onClick={() => app.openLink(url)}>
            <Attach16Filled style={{ transform: "rotate(135deg) translateY(-4px)" }} />{" "}
            {shortenFileName(fileName)}
          </Link>
        </Tooltip>
      ))}
    </TagGroup>
  );
}
