import React, { useEffect, useContext, useState, useCallback, useMemo } from "react";
import useAxios from "../../../lib/useAxios";
import { getFromLocalStorage } from "../../../lib/localStorage";
import { TeamsFxContext } from "../../../Context";
import {
  Card,
  Body1,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  useId,
  ProgressBar,
  SearchBox,
} from "@fluentui/react-components";
import { useSecureWebSocket } from "../../../lib/useSecureWebSocket";
import Announcement from "../../../components/Announcement/index";
import { FilterIncidents, defaultFilters } from "./FilterIncidents/index";
import { AssignedDataList } from "./AssignedDataList";
import { IncidentDetails } from "./IncidentDetails/index";

const buildUrlQueryFilter = (filters) => {
  return filters.assigned.length > 0
    ? `?filter=${filters.assigned.map((f) => f.key).join("&filter=")}`
    : "";
};

export const AssignedTabContent = () => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);
  const { lastMessage } = useSecureWebSocket(
    `${organizationApiUrl.replace("https", "wss")}/ws/connect`
  );

  const [firstLoading, setFirstLoading] = useState(true);
  const [preloadingData, setPreloadingData] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [assignedData, setAssignedData] = useState([]);
  const [assignedTotals, setAssignedTotals] = useState();
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState(() => {
    const storedFilters = getFromLocalStorage("__assigf");
    return storedFilters || defaultFilters;
  });
  const [rightOpen, setRightOpen] = useState(false);
  const [showLessColums, setShowLessColumns] = useState(false);
  const [selectedRow, setSelectedRow] = useState();

  const toasterId = useId("errorLoadingAssigned");
  const { dispatchToast } = useToastController(toasterId);

  const fetchIncidents = useCallback(
    async (appliedFilters) => {
      setPreloadingData(true);
      const loadingTimer = setTimeout(() => {
        setLoadingData(true);
      }, 3000);
      try {
        const query = buildUrlQueryFilter(appliedFilters);
        const { data } = await axiosInstance.get(`/incidents/assigned${query || ""}`);
        if (data) {
          setAssignedData(data.incidents);
          setAssignedTotals(data.totals);
        }
      } catch (e) {
        dispatchToast(
          <Toast>
            <ToastTitle>{e.message}</ToastTitle>
          </Toast>,
          { intent: "error", duration: 2800, position: "top" }
        );
      } finally {
        clearTimeout(loadingTimer);
        setFirstLoading(false);
        setPreloadingData(false);
        setLoadingData(false);
        setFilters(appliedFilters);
      }
    },
    [axiosInstance, dispatchToast]
  );

  useEffect(() => {
    if (firstLoading) {
      fetchIncidents(filters);
    }
  }, [firstLoading, fetchIncidents, filters]);

  useEffect(() => {
    if (lastMessage) {
      switch (lastMessage.type) {
        case "incident_update":
          const { case_id, ...updatedFields } = lastMessage.data;
          setAssignedData((currentIncidents) => {
            if (
              !currentIncidents.some((incident) => incident.case_id === lastMessage.data.case_id)
            ) {
              setAssignedTotals((currentTotals) => ({
                ...currentTotals,
                open_incidents: (currentTotals.open_incidents || 0) + 1,
              }));
              return [lastMessage.data, ...currentIncidents];
            } else {
              const updatedIncidents = currentIncidents.map((incident) =>
                incident.case_id === case_id ? { ...incident, ...updatedFields } : incident
              );
              // Update selected case if it's the current case
              if (selectedRow && selectedRow.case_id === case_id) {
                setSelectedRow((prevSelectedRow) => ({
                  ...prevSelectedRow,
                  ...updatedFields,
                }));
              }
              return updatedIncidents;
            }
          });
          break;
        default:
          console.debug("Unhandled message type:", lastMessage.type);
      }
    }
  }, [lastMessage]);

  const filteredAndSearchedData = useMemo(() => {
    let result = assignedData;

    if (filters.status.length > 0) {
      result = result.filter((item) => filters.status.some((f) => f.display === item.status));
    }

    if (filters.priority.length > 0) {
      result = result.filter((item) => filters.priority.some((f) => f.display === item.priority));
    }

    if (filters.type.length > 0) {
      result = result.filter((item) => filters.type.some((f) => f.key === item.classification));
    }

    if (searchText) {
      result = result.filter(
        (item) =>
          item.case_id.toLowerCase().includes(searchText.toLowerCase()) ||
          item.subject.toLowerCase().includes(searchText.toLowerCase()) ||
          item.summary.toLowerCase().includes(searchText.toLowerCase()) ||
          item.requester_name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.requester_email.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return result;
  }, [assignedData, filters, searchText]);

  const handleFiltersChange = useCallback(
    (newFilters) => {
      if (JSON.stringify(newFilters.assigned) !== JSON.stringify(filters.assigned)) {
        fetchIncidents(newFilters);
      } else {
        setFilters(newFilters);
      }
    },
    [filters, fetchIncidents]
  );

  const handleRowSelected = useCallback(
    (data) => {
      if (data !== null) {
        const selectedItemId = [...data.selectedItems][0];
        const selectedRowData = filteredAndSearchedData.find(
          (item) => item.case_id === selectedItemId
        );
        setSelectedRow(selectedRowData);
        setRightOpen(true);
      }
    },
    [filteredAndSearchedData]
  );

  return (
    <>
      <Toaster toasterId={toasterId} />
      {loadingData && <ProgressBar className="tab-loading" />}
      {!firstLoading && (
        <>
          {assignedTotals &&
          assignedTotals.open_incidents +
            assignedTotals.on_hold_incidents +
            assignedTotals.closed_incidents ===
            0 ? (
            <div className="flex-column">
              <Announcement
                imgSrc="nothing_to_do.png"
                imgAlt="No incident has been created yet"
                imgWidth="13em"
                title="No incident has been created yet"
                subtitle="Once the first incident is registered you will be able to visualize it in this session"
              />
            </div>
          ) : (
            <div className="inline-content">
              <div className="flex-row" style={{ padding: "10px 0px" }}>
                <FilterIncidents onFiltersChange={handleFiltersChange} />
                <SearchBox
                  appearance="filled-darker"
                  placeholder="Search"
                  value={searchText}
                  onChange={(_, data) => setSearchText(data.value)}
                  readOnly={preloadingData}
                />
              </div>
              <div className="root">
                <div className="assigned-content" style={{ marginBottom: "4em" }}>
                  <Card>
                    <AssignedDataList
                      list={filteredAndSearchedData}
                      onSelectRow={handleRowSelected}
                      setShowLessColumns={setShowLessColumns}
                      showLessColums={showLessColums}
                    />
                    {filteredAndSearchedData.length === 0 && (
                      <Body1 className="no-matching">
                        {searchText.trim() !== ""
                          ? `No matching incidents. Consider changing your search criteria or resetting filters.`
                          : `No incidents match the selected filters. Consider resetting filters.`}
                      </Body1>
                    )}
                  </Card>
                </div>
                {selectedRow && (
                  <IncidentDetails
                    open={rightOpen}
                    setOpen={setRightOpen}
                    position="end"
                    setShowLessColumns={setShowLessColumns}
                    onSelectRow={handleRowSelected}
                    selectedCase={selectedRow}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
