import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Field,
  Input,
  Combobox,
  Option,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  Avatar,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Textarea,
  Spinner,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import config from "../../../../lib/config";
import { debounce } from "lodash";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";

export const TeamDetailsDialog = ({ isOpen, onClose, mode, selectedTeam }) => {
  const { organizationApiUrl, teamsUserCredential } = useContext(TeamsFxContext);
  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [locations, setLocations] = useState([]);
  const [messages, setMessages] = useState([]);
  const [needConsent, setNeedConsent] = useState(false);

  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [teamNamePlaceholder, setTeamNamePlaceholder] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [industryProfiles, setIndustryProfiles] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [loadingUserSearch, setLoadingUserSearch] = useState(false);
  const [typing, setTyping] = useState(false);
  const [selectedTeammates, setSelectedTeammates] = useState([]);
  // Errors
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorProfiles, setShowErrorProfiles] = useState(false);
  const [showErrorTeammates, setShowErrorTeammates] = useState(false);

  useEffect(() => {
    const getLocations = async () => {
      try {
        const { data } = await axiosUserInstance.get("/org/locations");
        setLocations(data);
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Locations could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) =>
              setMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };
    const configureTeamsProfiles = async () => {
      try {
        const profileResponse = await axiosUserInstance.get("/org/profile");
        const url = new URL("/catalogs/industries/profiles", "http://my.com");
        profileResponse.data.categories.forEach((id) =>
          url.searchParams.append("category_ids", id)
        );
        const { data } = await axiosHubInstance.get(`${url.pathname}${url.search}`);
        if (data.length > 0) {
          setTeamNamePlaceholder(`e.g., ${data[0].suggestion}`);
        }
        setIndustryProfiles(data);
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Profiles could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) =>
              setMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };
    //getLocations();
    configureTeamsProfiles();
  }, []);

  useEffect(() => {
    if (mode === "edit" && selectedTeam) {
      setSelectedProfiles(selectedTeam.profiles || []);
      setSelectedTeammates(
        selectedTeam.teammates.map((m) => ({
          email: m.email,
          display_name: m.display_name,
          ms_id: m.ms_id,
          job_title: m.job_title,
        })) || []
      );
      setTeamName(selectedTeam.name);
      setTeamDescription(selectedTeam.description);
    } else {
      if (!isOpen) {
        setTimeout(() => {
          setTeamName("");
          setTeamDescription("");
          setSelectedProfiles([]);
          setSelectedTeammates([]);
          setShowErrorName(false);
          setShowErrorProfiles(false);
          setShowErrorTeammates(false);
          setMessages([]);
        }, 500);
      }
    }
  }, [mode, selectedTeam, isOpen]);

  const getOrganizationUsers = debounce(async (contain) => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential.login(["User.ReadBasic.All"]);
      setNeedConsent(false);
    }
    try {
      const { data: orgUsers } = await axiosUserInstance.get(`/msteams/users?contain=${contain}`);
      setOrganizationUsers(orgUsers);
    } catch (error) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      }
    } finally {
      setLoadingUserSearch(false);
    }
  }, 500);

  const saveTeams = async () => {
    let isValidForm = true;
    if (teamName.trim().length === 0) {
      setShowErrorName(true);
      isValidForm = false;
    }
    if (selectedProfiles.length === 0) {
      setShowErrorProfiles(true);
      isValidForm = false;
    }
    if (selectedTeammates.length === 0) {
      setShowErrorTeammates(true);
      isValidForm = false;
    }
    if (isValidForm) {
      if (mode === "create") {
        try {
          await axiosUserInstance.post("/org/teams", {
            name: teamName,
            description: teamDescription,
            profiles: selectedProfiles,
            teammates: selectedTeammates.map((m) => ({
              display_name: m.display_name,
              email: m.email,
              ms_id: m.ms_id,
              job_title: m.jobTitle,
            })),
          });
          onClose(true);
        } catch (e) {
          const bodyMsg = `Some unexpected error has occurred and Team could not be created. ${e.message}. Please, try again later`;
          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        }
      } else if (mode === "edit") {
        try {
          await axiosUserInstance.put("/org/teams", {
            id: selectedTeam.id,
            name: teamName,
            description: teamDescription,
            profiles: selectedProfiles,
            teammates: selectedTeammates.map((m) => ({
              display_name: m.display_name,
              email: m.email,
              ms_id: m.ms_id,
              job_title: m.job_title,
            })),
          });
          onClose(true);
        } catch (e) {
          const bodyMsg = `Some unexpected error has occurred and Team could not be updated. ${e.message}. Please, try again later`;
          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        }
      }
    }
  };

  const userSearchResult = organizationUsers.filter(
    (option) => !selectedTeammates.some((teammate) => teammate.email === option.email)
  );

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{mode === "create" ? "Add new Team" : "Edit Team"}</DialogTitle>
          <DialogContent>
            <MessageGroup messages={messages} />
            <div style={{ marginBottom: "4em", display: "grid", gap: "0.5em" }}>
              <Field
                label="Name"
                required
                validationMessage={showErrorName ? "Specify a name to identify the team" : ""}
              >
                <Input
                  placeholder={teamNamePlaceholder}
                  value={teamName}
                  onChange={(event) => {
                    setTeamName(event.target.value);
                    if (showErrorName && event.target.value.trim().length > 0)
                      setShowErrorName(false);
                  }}
                />
              </Field>
              <Field
                label="Profiles"
                required
                validationMessage={showErrorProfiles ? "Select at least one profile" : ""}
              >
                <Combobox
                  multiselect
                  placeholder="Select profiles"
                  selectedOptions={selectedProfiles}
                  readOnly
                  value={selectedProfiles.length > 0 ? selectedProfiles.join(", ") : ""}
                  onOptionSelect={(e, data) => {
                    setSelectedProfiles(data.selectedOptions);
                    if (showErrorProfiles) setShowErrorProfiles(false);
                  }}
                >
                  {industryProfiles.map((option) => (
                    <div key={option.profile_category}>
                      <span className="menu-subtitle">{option.profile_category}</span>
                      {option.profiles.map((profile) => (
                        <Option key={profile}>{profile}</Option>
                      ))}
                    </div>
                  ))}
                </Combobox>
              </Field>
              <Field
                label="Teammates"
                required
                validationMessage={showErrorTeammates ? "Select at least one teammate" : ""}
              >
                <TagPicker
                  onOptionSelect={(e, data) => {
                    setTyping(false);
                    setSelectedTeammates(data.selectedOptions);
                    if (showErrorTeammates) setShowErrorTeammates(false);
                  }}
                  selectedOptions={selectedTeammates}
                >
                  <TagPickerControl expandIcon={loadingUserSearch ? <Spinner size="tiny" /> : null}>
                    <TagPickerGroup>
                      {selectedTeammates.map((teammate) => (
                        <Tag
                          key={teammate.email}
                          shape="circular"
                          media={
                            <Avatar aria-hidden name={teammate.display_name} color="colorful" />
                          }
                          value={teammate}
                        >
                          {teammate.display_name}
                        </Tag>
                      ))}
                    </TagPickerGroup>
                    <TagPickerInput
                      aria-label="Enter teammate's name"
                      placeholder={selectedTeammates.length > 0 ? "" : "Enter teammate's name"}
                      onChange={(e) => {
                        setTyping(Boolean(e.target.value));
                        setLoadingUserSearch(true);
                        getOrganizationUsers(e.target.value);
                      }}
                      onBlur={(e) => setTyping(false)}
                    />
                  </TagPickerControl>
                  <TagPickerList>
                    {userSearchResult.length > 0
                      ? userSearchResult.map((user) => (
                          <TagPickerOption
                            media={
                              <Avatar
                                shape="circular"
                                aria-hidden
                                name={user.display_name}
                                color="colorful"
                              />
                            }
                            value={user}
                            key={user.email}
                          >
                            {user.display_name}
                          </TagPickerOption>
                        ))
                      : typing
                      ? loadingUserSearch || "We couldn't find any matches"
                      : "Enter teammate's name"}
                  </TagPickerList>
                </TagPicker>
              </Field>
              <Field label="Description">
                <Textarea
                  value={teamDescription}
                  maxLength={5000}
                  onChange={(event) => {
                    setTeamDescription(event.target.value);
                  }}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={saveTeams}>
              {mode === "create" ? "Create" : "Update"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
