// To save data
export const saveToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// To retrieve data
export const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const removeFromLocalStore = (key) => {
  if (!Array.isArray(key)) {
    localStorage.removeItem(key);
  } else {
    key.forEach((item) => {
      localStorage.removeItem(item);
    });
  }
};

export function parseJwt(token) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function updateLocalStorageFilter(key, property, value) {
  let filters = JSON.parse(localStorage.getItem(key)) || {};

  filters[property] = value;

  localStorage.setItem(key, JSON.stringify(filters));
}

export function getFromLocalStorageKey(key, property) {
  let filters = JSON.parse(localStorage.getItem(key)) || {};

  return filters[property] !== undefined ? filters[property] : null;
}
