import { useState, useRef } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import { AttachedFilesBar } from "./plugins/AttachedFilesBar";
import { Divider } from "@fluentui/react-components";
import { formatFileSize } from "../../lib/common";
import { MessageDialog } from "../MessageDialog";
import "./RichEditor.css";

function Placeholder({ text }) {
  return <div className="editor-placeholder">{text}</div>;
}

const theme = {
  ltr: "ltr",
  rtl: "rtl",
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  quote: "editor-quote",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  list: {
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
    listitem: "editor-listitem",
  },
  image: "editor-image",
  link: "editor-link",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    hashtag: "editor-text-hashtag",
    underline: "editor-text-underline",
    strikethrough: "editor-text-strikethrough",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
    code: "editor-text-code",
  },
  code: "editor-code",
  codeHighlight: {
    atrule: "editor-tokenAttr",
    attr: "editor-tokenAttr",
    boolean: "editor-tokenProperty",
    builtin: "editor-tokenSelector",
    cdata: "editor-tokenComment",
    char: "editor-tokenSelector",
    class: "editor-tokenFunction",
    "class-name": "editor-tokenFunction",
    comment: "editor-tokenComment",
    constant: "editor-tokenProperty",
    deleted: "editor-tokenProperty",
    doctype: "editor-tokenComment",
    entity: "editor-tokenOperator",
    function: "editor-tokenFunction",
    important: "editor-tokenVariable",
    inserted: "editor-tokenSelector",
    keyword: "editor-tokenAttr",
    namespace: "editor-tokenVariable",
    number: "editor-tokenProperty",
    operator: "editor-tokenOperator",
    prolog: "editor-tokenComment",
    property: "editor-tokenProperty",
    punctuation: "editor-tokenPunctuation",
    regex: "editor-tokenVariable",
    selector: "editor-tokenSelector",
    string: "editor-tokenSelector",
    symbol: "editor-tokenProperty",
    tag: "editor-tokenProperty",
    url: "editor-tokenOperator",
    variable: "editor-tokenVariable",
  },
};

const editorConfig = {
  // The editor theme
  theme: theme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export function RichEditor({
  placeholder,
  onTextChange,
  incidentType,
  onIncidentTypeChange,
  onCancel,
  onSend,
  displayUndoRedoBtns = true,
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const fileInputRef = useRef(null);

  const isFileSizeValid = (file) => {
    const maxSize = 15 * 1024 * 1024 * 1024; // 15 GB
    return file.size <= maxSize;
  };

  const handleFileChange = (event) => {
    const invalidFiles = Array.from(event.target.files).filter((file) => !isFileSizeValid(file));

    if (invalidFiles.length > 0) {
      const fileNames = invalidFiles.map((file) => file.name).join(", ");
      setDialogMessage(`The following files exceed the maximum size of 15 GB: ${fileNames}`);
      setIsDialogOpen(true);
    }

    const validFiles = Array.from(event.target.files)
      .filter(isFileSizeValid)
      .map((file) => ({
        file,
        name: file.name,
        size: formatFileSize(file.size),
        type: file.type,
      }));

    setSelectedFiles((prev) => {
      const newFiles = Array.from(validFiles).filter(
        (newFile) => !prev.some((existingFile) => existingFile.name === newFile.name)
      );
      return [...newFiles, ...prev];
    });
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = (fileName) => {
    setSelectedFiles(selectedFiles.filter((item) => item.name !== fileName));
  };

  const prepareFilesForUpload = () => {
    if (selectedFiles.length > 0) {
      // Solo enviamos la información necesaria para obtener las URLs de carga
      return selectedFiles.map(({ name, type, size }) => ({
        fileName: name,
        contentType: type,
        size: size,
      }));
    }
    return [];
  };

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="editor-container">
        <OnChangePlugin onChange={(editorState) => onTextChange(JSON.stringify(editorState))} />
        <div>
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={<Placeholder text={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <HistoryPlugin />
          <AutoFocusPlugin />
          <CodeHighlightPlugin />
          <ListPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <ListMaxIndentLevelPlugin maxDepth={7} />
          <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        </div>
        <AttachedFilesBar
          visible={selectedFiles.length > 0}
          selectedFiles={selectedFiles}
          onDeleteItem={handleDeleteFile}
        />
        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          multiple
          className="hidden"
        />
        <Divider />
        <ToolbarPlugin
          incidentType={incidentType}
          onIncidentTypeChange={onIncidentTypeChange}
          onCancel={onCancel}
          onSend={async () => {
            onSend(selectedFiles);
          }}
          displayUndoRedoBtns={displayUndoRedoBtns}
          onMediaClick={handleBrowseClick}
          mediaItemSelectedCount={selectedFiles.length}
        />
        <MessageDialog
          isOpen={isDialogOpen}
          onClose={setIsDialogOpen}
          title="Error uploading files"
          message={dialogMessage}
        />
      </div>
    </LexicalComposer>
  );
}
