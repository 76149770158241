import React from "react";
import { Tag } from "@fluentui/react-components";

const TagContainer = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  return (
    <div className="tag-container">
      {tags.map((tag) => (
        <Tag
          key={tag}
          shape="circular"
          size="extra-small"
          appearance="brand"
          className="child first-letter"
          style={{ paddingTop: "1px" }}
        >
          {tag}
        </Tag>
      ))}
    </div>
  );
};

export default TagContainer;
