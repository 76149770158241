import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Checkbox,
  RadioGroup,
  Radio,
} from "@fluentui/react-components";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";

export const TeamDeleteDialog = ({ isOpen, onClose, toDeleteTeam }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [teamName, setTeamName] = useState();
  const [confirmChecked, setConfirmChecked] = useState(false);

  const [hasSomeAssignedIncidents, setHasSomeAssignedIncidents] = useState();
  const [isLastTeam, setIsLastTeam] = useState();
  const [reassignOption, setReassignOption] = useState();

  useEffect(() => {
    const checkForAssignedIncidents = async () => {
      try {
        const { data } = await axiosUserInstance.get(`/org/teams/${toDeleteTeam?.id}/any_incident`);
        setHasSomeAssignedIncidents(data.have_any_incident);
        setIsLastTeam(!data.have_more_teams);
      } catch (e) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: `Some unexpected error has occurred and Team's data could not be obtained. ${e.message}. Please, try again later`,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    };
    if (isOpen) {
      setTeamName(toDeleteTeam?.name);
      checkForAssignedIncidents();
    } else {
      setTimeout(() => {
        setTeamName("");
        setConfirmChecked();
        setHasSomeAssignedIncidents();
        setIsLastTeam();
        setReassignOption();
        setMessages([]);
      }, 500);
    }
  }, [toDeleteTeam]);

  const handleChange = (ev, data) => {
    setConfirmChecked(Boolean(data.checked));
  };

  const handleDeleteAction = async () => {
    try {
      await axiosUserInstance.delete(
        `/org/teams/${toDeleteTeam?.id}?reassing=${reassignOption || "auto"}`
      );
      onClose(true);
    } catch (e) {
      const bodyMsg = `Some unexpected error has occurred and Team could not be deleted. ${e.message}. Please, try again later`;
      if (!messages.some((m) => m.body === bodyMsg)) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: bodyMsg,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Delete this Team?</DialogTitle>
          <DialogContent>
            <p>
              You're about to delete the Team "{teamName}".{" "}
              {isLastTeam &&
                "Please note that this is the latest Team, and without teams it won't be possible to automatically assign new incidents to technicians. "}
              {!isLastTeam && hasSomeAssignedIncidents
                ? `This Team has some incidents assigned to it, select the best option to reassign them:`
                : "Please confirm this action below."}
            </p>
            {!isLastTeam && hasSomeAssignedIncidents ? (
              <RadioGroup
                value={reassignOption}
                onChange={(_, data) => {
                  setReassignOption(data.value);
                  if (!confirmChecked) setConfirmChecked(true);
                }}
              >
                <Radio
                  value="auto"
                  label="Let Resolve Central try to reassign them to other Teams"
                />
                <Radio value="manual" label="Manually reassign them later" />
              </RadioGroup>
            ) : (
              <Checkbox
                checked={confirmChecked}
                onChange={handleChange}
                label="Yes, delete this Team"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!confirmChecked} appearance="primary" onClick={handleDeleteAction}>
              Delete
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
