import { useContext, useState } from "react";
import { Input, Persona } from "@fluentui/react-components";
import { authentication } from "@microsoft/teams-js";
import { TeamsFxContext } from "../../Context";
import useAxios from "../../lib/useAxios";
import { FileUploader } from "../../lib/fileUploader";
import { RichEditor } from "../../components/RichEditor";
import { extractTextFromJSON, getAuthName } from "../../lib/common";

/**
 * Componente para crear un nuevo comentario en un incidente
 *
 * @param  { string } case_id Incident case id
 * @param  { function } onCommentSent Occurs when submitting a new comment (no response from the backend yet)
 * @param  { function } onCommentProcessed Ocurrs after receiving a backend response
 */
export default function NewComment({ case_id, onCommentSent, onCommentProcessed }) {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [addingComment, setAddingComment] = useState(false);
  const [jsonContent, setJsonContent] = useState("");

  const sendNewComment = async (selectedFiles) => {
    try {
      setAddingComment(false);
      onCommentSent(jsonContent);
      const haveAttachments = selectedFiles && selectedFiles.length > 0;
      // Upload files if exist
      let attachments = [];
      if (haveAttachments) {
        // Get SharePoint upload urls
        const sso_token = await authentication.getAuthToken();
        const uploadRequest = {
          sso_token: sso_token,
          files: selectedFiles.map((file) => ({ fileName: file.name })),
          caseId: case_id,
        };
        const { data: uploadResponse } = await axiosUserInstance.post(
          "/msteams/create-upload-session",
          uploadRequest
        );
        // Upload files
        const uploader = new FileUploader((progress) => {
          /* TODO: show progress bar for large files -> console.debug("Upload progress:", progress); */
        });

        const results = await uploader.uploadFiles(selectedFiles, uploadResponse.uploadSessions);
        const successfulUploads = results.filter((result) => result && result.status === "success");
        /* TODO: handdle fails -> const failedUploads = results.filter((result) => result && result.status === "error");
        console.debug("Failed uploads:", failedUploads); */
        attachments = successfulUploads.map((file) => ({
          fileName: file.fileName,
          url: file.webUrl,
          downloadUrl: file.webUrl,
          size: file.size,
        }));
      }
      // Send the comment
      const plainContent = extractTextFromJSON(jsonContent);
      const { data } = await axiosUserInstance.post("/incidents/comment", {
        case_id: case_id,
        raw_comment: jsonContent,
        plain_comment: plainContent,
      });
      // If there are attached files, update the file information with the comment ID
      if (haveAttachments) {
        await axiosUserInstance.post("/incidents/attachments", {
          caseId: case_id,
          attachments: attachments.map((att) => ({ ...att, commentId: data.comment_id })),
        });
      }
      onCommentProcessed(true, plainContent);
    } catch (e) {
      onCommentProcessed(false, e.message);
    }
  };

  return (
    <div>
      {!addingComment ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px 10px 10px",
          }}
        >
          <Persona
            size="small"
            name={getAuthName()}
            primaryText=""
            avatar={{ color: "colorful" }}
          />
          <Input
            readOnly={true}
            onClick={() => setAddingComment(!addingComment)}
            placeholder="Write a comment"
            style={{ width: "100%" }}
            appearance="filled-darker"
          />
        </div>
      ) : (
        <RichEditor
          placeholder="Add new comment here..."
          displayUndoRedoBtns={false}
          onTextChange={setJsonContent}
          onCancel={() => setAddingComment(false)}
          onSend={(files) => sendNewComment(files)}
        />
      )}
    </div>
  );
}
