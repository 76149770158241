import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useState, useContext } from "react";
import {
  NavCategory,
  NavCategoryItem,
  NavDivider,
  NavDrawer,
  NavDrawerBody,
  NavDrawerHeader,
  NavItem,
  NavSectionHeader,
  NavSubItem,
  NavSubItemGroup,
  Hamburger,
} from "@fluentui/react-nav-preview";
import { Tooltip } from "@fluentui/react-components";

import {
  Board20Filled,
  Board20Regular,
  BoxMultiple20Filled,
  BoxMultiple20Regular,
  DataArea20Filled,
  DataArea20Regular,
  DocumentBulletListMultiple20Filled,
  DocumentBulletListMultiple20Regular,
  Building20Filled,
  Building20Regular,
  PeopleCommunity20Filled,
  PeopleCommunity20Regular,
  PeopleStar20Filled,
  PeopleStar20Regular,
  ScreenSearch20Filled,
  ScreenSearch20Regular,
  TagMultiple20Regular,
  TagMultiple20Filled,
  LocationRipple20Regular,
  LocationRipple20Filled,
  bundleIcon,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../Context";
import { haveRole } from "../../lib/common";
import { Dashboard } from "./Dashboard";
import { Incidents } from "./Incidents";
import { Teams } from "./Teams";
import { Tags } from "./Tags";
import { Organization } from "./Organization";

// TODO: update notes in UI when closing the case
// TODO: Barras paro verticales

const DashboardIcon = bundleIcon(Board20Filled, Board20Regular);
const IncidentsSearch = bundleIcon(ScreenSearch20Filled, ScreenSearch20Regular);
const OrganizationIcon = bundleIcon(Building20Filled, Building20Regular);
const TeamsIcon = bundleIcon(PeopleCommunity20Filled, PeopleCommunity20Regular);
const LocationsIcon = bundleIcon(LocationRipple20Filled, LocationRipple20Regular);
const TagsIcon = bundleIcon(TagMultiple20Filled, TagMultiple20Regular);
const TrainingPrograms = bundleIcon(BoxMultiple20Filled, BoxMultiple20Regular);
const CareerDevelopment = bundleIcon(PeopleStar20Filled, PeopleStar20Regular);
const Analytics = bundleIcon(DataArea20Filled, DataArea20Regular);
const Reports = bundleIcon(DocumentBulletListMultiple20Filled, DocumentBulletListMultiple20Regular);

export function AdminScreen() {
  const navigate = useNavigate();
  const { organizationName } = useContext(TeamsFxContext);

  const [isOpenMenu, setIsOpenMenu] = useState(true);

  const handleNavItemSelect = (e, data) => {
    navigate(data.value);
  };

  const renderHamburgerWithToolTip = () => {
    return (
      <Tooltip content="Navigation" relationship="label">
        <Hamburger onClick={() => setIsOpenMenu(!isOpenMenu)} />
      </Tooltip>
    );
  };

  const isAdminOwner = haveRole("owner");

  return (
    <div className="layout-container">
      <div className={`navbar-container ${isOpenMenu ? "navbar-container-full" : ""}`}>
        <NavDrawer
          defaultSelectedValue="all_incidents"
          open={isOpenMenu}
          type="inline"
          onNavItemSelect={handleNavItemSelect}
        >
          <NavDrawerHeader>
            {renderHamburgerWithToolTip()}
            <b style={{ padding: "0.4em" }}>{organizationName}</b>
          </NavDrawerHeader>
          <NavDrawerBody>
            <NavItem icon={<IncidentsSearch />} value="all_incidents">
              Incidents
            </NavItem>
            {/* <NavItem icon={<DashboardIcon />} value="dash">
              Dashboard
            </NavItem> */}
            <NavSectionHeader>Organization Setup</NavSectionHeader>
            <NavItem icon={<TeamsIcon />} value="setup_teams">
              Technician Teams
            </NavItem>
            {/* <NavItem icon={<LocationsIcon />} value="setup_locations">
            Locations
          </NavItem> */}
            <NavItem icon={<TagsIcon />} value="setup_tags">
              Tags
            </NavItem>
            {isAdminOwner && (
              <NavItem icon={<OrganizationIcon />} value="setup_organization">
                Organization
              </NavItem>
            )}
            {/* isAdminOwner && (
              <>
                <NavSectionHeader>Subscription</NavSectionHeader>
                <NavItem icon={<TrainingPrograms />} value="setup_subscription">
                  Current Subscription
                </NavItem>
                <NavCategory value="billing_history">
                  <NavCategoryItem icon={<CareerDevelopment />}>Billing History</NavCategoryItem>
                  <NavSubItemGroup>
                    <NavSubItem value="17">Career Paths</NavSubItem>
                    <NavSubItem value="18">Planning</NavSubItem>
                  </NavSubItemGroup>
                </NavCategory>
                <NavDivider />
                <NavItem target="_blank" icon={<Analytics />} value="19">
                  Workforce Data
                </NavItem>
                <NavItem icon={<Reports />} value="20">
                  Reports
                </NavItem>
              </>
            ) */}
          </NavDrawerBody>
        </NavDrawer>
        {!isOpenMenu && renderHamburgerWithToolTip()}
      </div>
      <div className="admin-container">
        <div className="scrollable-content">
          <Routes>
            <Route path="all_incidents" element={<Incidents />} />
            {/* <Route path="dash" element={<Dashboard />} /> */}
            <Route path="setup_teams" element={<Teams />} />
            <Route path="setup_tags" element={<Tags />} />
            <Route path="setup_organization" element={<Organization />} />
            <Route path="*" element={<Navigate to="all_incidents" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
