import { useContext, useState } from "react";
import { useData } from "@microsoft/teamsfx-react";
import { authentication } from "@microsoft/teams-js";
import config from "../../lib/config";
import useAxios from "../../lib/useAxios";
import { TeamsFxContext } from "../../Context";
import Announcement from "../../components/Announcement";
import { Signup } from "./Signup";

export const NotConfiguredApp = () => {
  const { teamsUserCredential } = useContext(TeamsFxContext);
  const [needConsent, setNeedConsent] = useState(false);
  const { axiosInstance: axiosPublicInstance } = useAxios(config.publicCloudEndpoint, false);

  const { loading, data } = useData(async () => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential.login([
        "User.Read",
        "User.ReadBasic.All",
        "Directory.Read.All",
        "Sites.ReadWrite.All",
        "TeamsActivity.Send",
      ]);
      setNeedConsent(false);
    }
    try {
      const sso_token = await authentication.getAuthToken();
      const { data: response } = await axiosPublicInstance.post(
        "/msteams/delegated/is-admin",
        {},
        {
          headers: {
            authorization: sso_token,
          },
        }
      );
      return response;
    } catch (error) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      }
    }
  });

  return (
    <>
      {!loading && (
        <div className="flex-column">
          {data?.isAdmin ? (
            <Signup />
          ) : (
            <>
              <div style={{ marginTop: "5%" }}></div>
              <Announcement
                imgSrc="something_is_missing.png"
                imgAlt="Sorry, you don't have access to this app"
                imgWidth="15em"
                title="Sorry, you don't have access to this app"
                subtitle="To set up this app for your organization, administrator permissions are required. 
              Please contact your IT department or system administrator to get started"
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
