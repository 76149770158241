import { useState, useEffect, useCallback, useRef } from "react";
import { getFromLocalStorage } from "./localStorage";

const RECONNECT_INTERVAL = 5000; // Intenta reconectar cada 5 segundos
const MAX_RECONNECT_ATTEMPTS = 5; // Número máximo de intentos de reconexión

export function useSecureWebSocket(url) {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [lastMessage, setLastMessage] = useState(null);
  const [errorState, setErrorState] = useState(null);

  const reconnectAttempts = useRef(0);
  const reconnectTimeoutId = useRef(null);

  const connect = useCallback(() => {
    const newSocket = new WebSocket(url);

    newSocket.onopen = () => {
      const token = getFromLocalStorage("__atkn");
      if (!token) {
        setErrorState("No authentication token found");
        newSocket.close();
        return;
      }
      newSocket.send(JSON.stringify({ type: "auth", token }));
      reconnectAttempts.current = 0; // Resetea los intentos de reconexión
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === "auth" && data.status === "success") {
        setIsConnected(true);
        setErrorState(null);
      } else if (data.type === "incident_update") {
        setLastMessage(data);
      } else if (data.type === "heartbeat") {
        // Responde al heartbeat del servidor
        newSocket.send(JSON.stringify({ type: "heartbeat_ack" }));
      }
    };

    newSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
      setErrorState("WebSocket encountered an error");
    };

    newSocket.onclose = (event) => {
      setIsConnected(false);

      if (!event.wasClean && reconnectAttempts.current < MAX_RECONNECT_ATTEMPTS) {
        reconnectAttempts.current++;
        reconnectTimeoutId.current = setTimeout(() => {
          console.debug(`Attempting to reconnect... (Attempt ${reconnectAttempts.current})`);
          connect();
        }, RECONNECT_INTERVAL);
      } else if (reconnectAttempts.current >= MAX_RECONNECT_ATTEMPTS) {
        setErrorState("Max reconnection attempts reached. Please refresh the page.");
      }
    };

    setSocket(newSocket);
  }, [url]);

  useEffect(() => {
    connect();

    return () => {
      if (socket) {
        socket.close();
      }
      if (reconnectTimeoutId.current) {
        clearTimeout(reconnectTimeoutId.current);
      }
    };
  }, [connect]);

  const sendMessage = useCallback(
    (message) => {
      if (socket && isConnected) {
        socket.send(JSON.stringify(message));
      } else {
        console.error("Cannot send message, socket is not connected");
      }
    },
    [socket, isConnected]
  );

  return { socket, isConnected, lastMessage, errorState, sendMessage };
}
