import { useState, useRef } from "react";
import { Tab, TabList } from "@fluentui/react-components";
import { AssignedTabContent } from "./AssignedTabContent/index";
import { RequesterScreen } from "../RequesterScreen";

export function TechnicianScreen() {
  const [selectedIncidentsTab, setSelectedIncidentsTab] = useState("assignedTab"); // TODO: use last selected value
  const tab = useRef("assignedTab");

  return (
    <div className="app-body">
      <div className="tab-container">
        <TabList
          defaultSelectedValue={tab.current}
          appearance="transparent"
          onTabSelect={(_, { value }) => setSelectedIncidentsTab(value)}
        >
          <Tab value="assignedTab">Assigned</Tab>
          <Tab value="myRequestTab">My requests</Tab>
        </TabList>
      </div>
      <div className="tab-content">
        {selectedIncidentsTab === "assignedTab" && <AssignedTabContent />}
        {selectedIncidentsTab === "myRequestTab" && <RequesterScreen />}
      </div>
    </div>
  );
}
