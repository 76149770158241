import { useState, useCallback, useEffect } from "react";
import { getFromLocalStorage, saveToLocalStorage } from "./localStorage";

export const useFilters = (storageKey, defaultFilters, onFiltersChange) => {
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const storedFilters = getFromLocalStorage(storageKey);
    return storedFilters || defaultFilters;
  });

  const handleApplyFilter = useCallback((filterGroup, newOption) => {
    setSelectedFilters((prevFilters) => {
      const optionExists = prevFilters[filterGroup].some((option) => option.key === newOption.key);
      if (optionExists) {
        return prevFilters;
      }
      return {
        ...prevFilters,
        [filterGroup]: [...prevFilters[filterGroup], newOption],
      };
    });
  }, []);

  const handleRemoveFilter = useCallback((filterGroup, optionKey) => {
    setSelectedFilters((prevFilters) => {
      const updatedGroupFilters = prevFilters[filterGroup].filter(
        (option) => option.key !== optionKey
      );
      return {
        ...prevFilters,
        [filterGroup]: updatedGroupFilters,
      };
    });
  }, []);

  const resetFilters = useCallback(() => {
    setSelectedFilters(defaultFilters);
  }, [defaultFilters]);

  useEffect(() => {
    saveToLocalStorage(storageKey, selectedFilters);
    onFiltersChange(selectedFilters);
  }, [selectedFilters, onFiltersChange, storageKey]);

  const getFlattenedFilters = useCallback(() => {
    return Object.entries(selectedFilters).flatMap(([group, filters]) =>
      filters.map((filter) => ({
        group,
        ...filter,
      }))
    );
  }, [selectedFilters]);

  return {
    selectedFilters,
    handleApplyFilter,
    handleRemoveFilter,
    getFlattenedFilters,
    resetFilters,
  };
};
