import { useContext, useState } from "react";
import {
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  ToggleButton,
  Divider,
  Persona,
  Image,
  Text,
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Toaster,
  Toast,
  ToastTitle,
  useToastController,
  useId,
} from "@fluentui/react-components";
import { authentication } from "@microsoft/teams-js";
import { TeamsFxContext } from "../../../Context";
import useAxios from "../../../lib/useAxios";
import { FileUploader } from "../../../lib/fileUploader";
import { RichEditor } from "../../../components/RichEditor";
import { extractTextFromJSON, getAuthName } from "../../../lib/common";

export function ReportNewIncident({ onIncidentSubmitted }) {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const toasterId = useId("newIncidentResult");
  const { dispatchToast } = useToastController(toasterId);

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [incidentType, setIncidentType] = useState("incident");
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);

  const [jsonContent, setJsonContent] = useState("");
  const [preloadedFiles, setPreloadedFiles] = useState([]);
  const [sendingRequest, setSendingRequest] = useState(false);

  const submitNewIncident = async () => {
    setSendingRequest(true);
    try {
      const { data } = await axiosUserInstance.post("/incidents/submit", {
        classification: incidentType,
        raw_content: jsonContent,
        plain_content: extractTextFromJSON(jsonContent),
      });

      const haveAttachments = data.case_id && preloadedFiles && preloadedFiles.length > 0;
      // Upload files if exist
      if (haveAttachments) {
        // Get SharePoint upload urls
        const sso_token = await authentication.getAuthToken();
        const uploadRequest = {
          sso_token: sso_token,
          files: preloadedFiles.map((file) => ({ fileName: file.name })),
          caseId: data.case_id,
        };
        const { data: uploadResponse } = await axiosUserInstance.post(
          "/msteams/create-upload-session",
          uploadRequest
        );
        // Upload files
        const uploader = new FileUploader((progress) => {
          /* TODO: show progress bar for large files -> console.debug("Upload progress:", progress); */
        });

        const results = await uploader.uploadFiles(preloadedFiles, uploadResponse.uploadSessions);
        const successfulUploads = results.filter((result) => result && result.status === "success");
        /* TODO: handdle fails -> const failedUploads = results.filter((result) => result && result.status === "error");
        console.debug("Failed uploads:", failedUploads); */

        await axiosUserInstance.post("/incidents/attachments", {
          caseId: data.case_id,
          attachments: successfulUploads.map((file) => ({
            fileName: file.fileName,
            url: file.webUrl,
            downloadUrl: file.webUrl,
            size: file.size,
          })),
        });
      }

      setIsCollapsed(true);
      setIsOpenConfirmDialog(false);
      dispatchToast(
        <Toast>
          <ToastTitle>Case "{data.case_id}" was created.</ToastTitle>
        </Toast>,
        { intent: "success", duration: 2800, position: "top" }
      );
      onIncidentSubmitted();
      return true;
    } catch (e) {
      console.debug("Failed to report incident", e.message);
      dispatchToast(
        <Toast>
          <ToastTitle>{e.message}</ToastTitle>
        </Toast>,
        { intent: "error", duration: 2800, position: "top" }
      );
    } finally {
      setSendingRequest(false);
    }
  };

  const handleIncidentOptionClick = () => {
    setIncidentType("incident");
    setIsCollapsed(false);
  };

  const handleRequestOptionClick = () => {
    setIncidentType("request");
    setIsCollapsed(false);
  };

  return (
    <div className="flex-row">
      <Toaster toasterId={toasterId} />
      <Card
        style={{
          padding: "1em",
          width: "100%",
        }}
      >
        {isCollapsed && (
          <CardHeader
            image={
              <Persona
                size="medium"
                name={getAuthName()}
                primaryText=""
                avatar={{ color: "colorful" }}
              />
            }
            description={
              <Text onClick={() => setIsCollapsed(!isCollapsed)} className="incident-placeholder">
                {`Provide a detailed description of your ${incidentType}...`}
              </Text>
            }
          />
        )}

        {!isCollapsed && (
          <CardPreview>
            <RichEditor
              placeholder={`Provide a detailed description of your ${incidentType}...`}
              onTextChange={setJsonContent}
              incidentType={incidentType}
              displayUndoRedoBtns={false}
              onIncidentTypeChange={(e) =>
                setIncidentType(e.currentTarget.getAttribute("data-value"))
              }
              onCancel={() => setIsCollapsed(true)}
              onSend={(files) => {
                setPreloadedFiles(files);
                setIsOpenConfirmDialog(true);
              }}
            />
          </CardPreview>
        )}

        {isCollapsed && (
          <>
            <Divider />
            <CardFooter>
              <ToggleButton
                appearance="subtle"
                checked={incidentType === "incident"}
                onClick={handleIncidentOptionClick}
                size="large"
                icon={<Image src="/icons/incident48.png" style={{ width: "24px" }} />}
              >
                Incident
              </ToggleButton>
              <ToggleButton
                appearance="subtle"
                checked={incidentType === "request"}
                onClick={handleRequestOptionClick}
                size="large"
                icon={<Image src="/icons/request48.png" style={{ width: "30px" }} />}
              >
                Request
              </ToggleButton>
            </CardFooter>
          </>
        )}
      </Card>

      <Dialog modalType="alert" open={isOpenConfirmDialog}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Confirm Submission</DialogTitle>
            <DialogContent>Are you ready to submit this {incidentType}?</DialogContent>
            <DialogActions>
              {!sendingRequest && (
                <Button appearance="secondary" onClick={() => setIsOpenConfirmDialog(false)}>
                  Close
                </Button>
              )}
              <Button appearance="primary" disabled={sendingRequest} onClick={submitNewIncident}>
                {sendingRequest ? "Submitting..." : "Submit"}
              </Button>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}
