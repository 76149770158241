import { useState, useEffect, useContext, useCallback } from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Field,
  InfoLabel,
  Switch,
  TagPicker,
  TagPickerControl,
  TagPickerGroup,
  Tag,
  Avatar,
  TagPickerInput,
  TagPickerList,
  TagPickerOption,
  Spinner,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import { debounce } from "lodash";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";

export const NewAdminDialog = ({ isOpen, onClose, toBeExcluded }) => {
  const { organizationApiUrl, teamsUserCredential } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [needConsent, setNeedConsent] = useState(false);

  const [loadingUserSearch, setLoadingUserSearch] = useState(false);
  const [typing, setTyping] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [isOrgOwner, setIsOrgOwner] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  // Errors
  const [showErrorAdmins, setShowErrorAdmins] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSelectedAdmins([]);
        setIsOrgOwner(false);
        setShowErrorAdmins(false);
        setMessages([]);
      }, 500);
    }
  }, [isOpen]);

  const getOrganizationUsers = debounce(async (contain) => {
    if (!teamsUserCredential) {
      throw new Error("TeamsFx SDK is not initialized.");
    }
    if (needConsent) {
      await teamsUserCredential.login(["User.ReadBasic.All"]);
      setNeedConsent(false);
    }
    try {
      const { data: orgUsers } = await axiosUserInstance.get(`/msteams/users?contain=${contain}`);
      setOrganizationUsers(orgUsers);
    } catch (error) {
      if (error.message.includes("The application may not be authorized.")) {
        setNeedConsent(true);
      }
    } finally {
      setLoadingUserSearch(false);
    }
  }, 500);

  const handleOwnerChecked = useCallback(
    (ev) => {
      setIsOrgOwner(ev.currentTarget.checked);
    },
    [setIsOrgOwner]
  );

  const addAdmin = async () => {
    if (selectedAdmins.length === 0) {
      setShowErrorAdmins(true);
    } else {
      try {
        await axiosUserInstance.post("/org/admins", {
          is_owner: isOrgOwner,
          admins: selectedAdmins.map((m) => ({
            display_name: m.display_name,
            email: m.email,
            ms_id: m.ms_id,
            job_title: null,
          })),
        });
        onClose(true);
      } catch (e) {
        const bodyMsg = `Some unexpected error has occurred and Admins could not be added. ${e.message}. Please, try again later`;
        if (!messages.some((m) => m.body === bodyMsg)) {
          setMessages((prevMessages) => [
            {
              id: generateRandomId(8),
              intent: "error",
              title: "Oops!",
              body: bodyMsg,
              dispatchAction: (msgId) =>
                setMessages((s) => s.filter((entry) => entry.ms_id !== msgId)),
            },
            ...prevMessages,
          ]);
        }
      }
    }
  };

  const userSearchResult = organizationUsers
    .filter((u) => !toBeExcluded.some((exc) => exc.email === u.email))
    .filter((option) => !selectedAdmins.some((teammate) => teammate.email === option.email));

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Add new Admin</DialogTitle>
          <DialogContent>
            <MessageGroup messages={messages} />
            <div style={{ marginBottom: "4em", display: "grid", gap: "0.5em" }}>
              <Field
                label="Organization admins"
                required
                validationMessage={showErrorAdmins ? "Select at least one user" : ""}
              >
                <TagPicker
                  onOptionSelect={(e, data) => {
                    setTyping(false);
                    setSelectedAdmins(data.selectedOptions);
                    if (showErrorAdmins) setShowErrorAdmins(false);
                  }}
                  selectedOptions={selectedAdmins}
                >
                  <TagPickerControl expandIcon={loadingUserSearch ? <Spinner size="tiny" /> : null}>
                    <TagPickerGroup>
                      {selectedAdmins.map((teammate) => (
                        <Tag
                          key={teammate.email}
                          shape="circular"
                          media={
                            <Avatar aria-hidden name={teammate.display_name} color="colorful" />
                          }
                          value={teammate}
                        >
                          {teammate.display_name}
                        </Tag>
                      ))}
                    </TagPickerGroup>
                    <TagPickerInput
                      aria-label="Enter a name"
                      placeholder={selectedAdmins.length > 0 ? "" : "Enter a name"}
                      onChange={(e) => {
                        setTyping(Boolean(e.target.value));
                        setLoadingUserSearch(true);
                        getOrganizationUsers(e.target.value);
                      }}
                      onBlur={(e) => setTyping(false)}
                    />
                  </TagPickerControl>
                  <TagPickerList>
                    {userSearchResult.length > 0
                      ? userSearchResult.map((user) => (
                          <TagPickerOption
                            media={
                              <Avatar
                                shape="circular"
                                aria-hidden
                                name={user.display_name}
                                color="colorful"
                              />
                            }
                            value={user}
                            key={user.email}
                          >
                            {user.display_name}
                          </TagPickerOption>
                        ))
                      : typing
                      ? loadingUserSearch || "We couldn't find any matches"
                      : "Enter a name"}
                  </TagPickerList>
                </TagPicker>
              </Field>
              <div
                className="horizontal-content"
                style={{ marginTop: "1em", alignItems: "center" }}
              >
                <Switch
                  label="Is Organization Owner"
                  checked={isOrgOwner}
                  onChange={handleOwnerChecked}
                />
                <InfoLabel
                  info={
                    <>
                      <span>Organization owners will have additional access to:</span>
                      <ul>
                        <li>Update the organization's profile</li>
                        <li>Manage other administrators</li>
                        <li>Manage subscriptions and bills</li>
                      </ul>
                    </>
                  }
                ></InfoLabel>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" onClick={addAdmin}>
              Add
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
