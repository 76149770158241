import React, { useState } from "react";
import { Image, Subtitle1, Body1 } from "@fluentui/react-components";

const Announcement = ({ imgSrc, imgAlt, imgWidth = "15em", title, subtitle, actionComponent }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className="announcement-container" style={{ visibility: isLoaded ? "visible" : "hidden" }}>
      <Image src={imgSrc} alt={imgAlt} style={{ width: imgWidth }} onLoad={handleImageLoad} />
      <Subtitle1 style={{ paddingBottom: "1em" }}>{title}</Subtitle1>
      <Body1 style={{ paddingBottom: "3em", textAlign: "center" }}>{subtitle}</Body1>
      {actionComponent}
    </div>
  );
};

export default Announcement;
