import { useState, Fragment, useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Divider,
  Image,
  Badge,
  Title3,
  Persona,
  Caption1,
  Menu,
  MenuTrigger,
  MenuButton,
  MenuPopover,
  MenuList,
  MenuItem,
} from "@fluentui/react-components";
import {
  MoreHorizontal20Regular,
  bundleIcon,
  ArchiveFilled,
  ArchiveRegular,
  HistoryFilled,
  HistoryRegular,
  CircleFilled,
  ChevronDownRegular,
  ChevronRightRegular,
} from "@fluentui/react-icons";
import { TeamsFxContext } from "../../../Context";
import { authentication } from "@microsoft/teams-js";
import useAxios from "../../../lib/useAxios";
import TagContainer from "../../../components/TagContainer/TagContainer";
import CommentReadOnly from "../../../components/CommentReadOnly";
import NewComment from "../../../components/NewComment";
import { CloseDialog } from "../../../components/IncidentActions/CloseDialog";
import { HistoryDialog } from "../HistoryDialog";
import { MessageGroup } from "../../../components/MessageGroup";
import { RichEditorReadOnly } from "../../../components/RichEditorReadOnly";
import { AttachmentList } from "../../../components/AttachmentList";
import { Collapse } from "@fluentui/react-motion-components-preview";
import { getHumanDate, getFullDateTime, getAuthName } from "../../../lib/common";

const CloseIcon = bundleIcon(ArchiveFilled, ArchiveRegular);
const HistoryIcon = bundleIcon(HistoryFilled, HistoryRegular);

export const IncidentPost = ({ incident, onPostClose }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance } = useAxios(organizationApiUrl);

  const [collapsed, setCollapsed] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [comments, setComments] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [history, setHistory] = useState([]);
  const [errorOnMessage, setErrorOnMessage] = useState();
  const [isOpenHistoryDialog, setIsOpenHistoryDialog] = useState(false);
  const [isOpenCloseDialog, setIsOpenCloseDialog] = useState(false);

  const fetchCaseDetails = async () => {
    setLoadingData(true);
    try {
      const { data } = await axiosInstance.get(`/incidents/details/${incident.case_id}`);
      if (data) {
        setComments(data.comments);
        setHistory(data.history);
        setAttachments(data.attachments);
      }
    } catch (e) {
      console.debug(e);
    } finally {
      setLoadingData(false);
    }
  };

  const sendNotificationToUser = async (recipient_ms_id, title, preview_text) => {
    try {
      const sso_token = await authentication.getAuthToken();
      await axiosInstance.post("/msteams/notify", {
        sso_token,
        recipient_ms_id,
        title,
        preview_text,
      });
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    fetchCaseDetails();
  }, [incident]);

  const addCommentToList = (jsonContent) => {
    const newComment = {
      by_user_name: getAuthName(),
      comment_date: new Date().toISOString(),
      raw_comment: jsonContent,
      isLoading: true,
    };
    setComments((prevComments) => [...prevComments, newComment]);
  };

  const updateCommentStatus = (processed, details) => {
    fetchCaseDetails();
    if (!processed) {
      setErrorOnMessage({
        id: "500",
        intent: "error",
        title: "Oops!",
        body: `Something went wrong sending the comment: ${details}`,
        dispatchAction: () => setErrorOnMessage(undefined),
      });
      setTimeout(() => {
        setErrorOnMessage(undefined);
      }, 10000);
    } else {
      // Send a notification to technician assigned
      if (incident.assigned_msid) {
        const prefix = ["Completed", "Canceled"].includes(incident.status) ? `[Closed] ` : "";
        sendNotificationToUser(
          incident.assigned_msid,
          `💬 ${prefix}#${incident.case_id} · ${incident.subject}`,
          details
        );
      }
    }
  };

  const handleAfterClose = (action) => {
    if (action.notifications.length > 0) {
      action.notifications.forEach((notification) => {
        sendNotificationToUser(notification.recipient, notification.title, notification.preview);
      });
    }
    onPostClose();
  };

  return (
    <div style={{ paddingBottom: "1em" }}>
      <HistoryDialog
        history={history}
        isOpen={isOpenHistoryDialog}
        onClose={() => setIsOpenHistoryDialog(false)}
      />
      <CloseDialog
        isOpen={isOpenCloseDialog}
        incident={incident}
        onClose={(refresh, notification) => {
          setIsOpenCloseDialog(false);
          if (refresh) handleAfterClose(notification);
        }}
      />
      <Card style={{ padding: "0px", rowGap: "0px" }}>
        <CardHeader
          style={{ padding: "0.5em", paddingLeft: "0.7em" }}
          header={
            <div className="status-bar">
              {!incident.priority ? (
                <>
                  <Image
                    src={`/icons/processing.png`}
                    style={{ width: "16px", marginRight: "3px" }}
                  />
                  <Caption1 className="additional-text">
                    • Processing {incident.classification}
                  </Caption1>
                </>
              ) : (
                <>
                  <Image
                    src={`/icons/${incident.classification}${incident.status}48.png`}
                    style={{ width: "16px" }}
                  />
                  <span
                    className={`tag-status-${incident.status} pill-tag`}
                    style={{ marginInline: ".5em" }}
                  >
                    {incident.status}
                  </span>
                  <Caption1 className="additional-text" style={{ marginRight: ".5em" }}>
                    •
                  </Caption1>
                  <Caption1 className="additional-text">
                    Updated {getHumanDate(incident.updated_at)}
                  </Caption1>
                </>
              )}
            </div>
          }
          action={
            <div className="flex-row">
              <Badge appearance="ghost">#{incident.case_id}</Badge>
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <MenuButton
                    appearance="transparent"
                    icon={<MoreHorizontal20Regular />}
                  ></MenuButton>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    {!loadingData && (
                      <MenuItem icon={<HistoryIcon />} onClick={() => setIsOpenHistoryDialog(true)}>
                        See Hitory
                      </MenuItem>
                    )}
                    <MenuItem icon={<CloseIcon />} onClick={() => setIsOpenCloseDialog(true)}>
                      Close incident
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
              <Button
                appearance="transparent"
                icon={collapsed ? <ChevronDownRegular /> : <ChevronRightRegular />}
                onClick={() => setCollapsed((c) => !c)}
              />
            </div>
          }
        />

        <Collapse visible={collapsed}>
          <div>
            <div style={{ paddingInline: "1em" }}>
              <Title3>{incident.subject}</Title3>
              <div style={{ padding: "10px 0px 25px 0px" }}>
                <TagContainer tags={incident.tags} />
              </div>

              <Persona
                size="medium"
                name={incident.requester_name}
                primaryText={<b>{incident.requester_name}</b>}
                avatar={{ color: "colorful" }}
                secondaryText={
                  <Caption1>
                    {"Requested"} · {getFullDateTime(incident.created_at)}
                  </Caption1>
                }
              />

              <RichEditorReadOnly initialContent={JSON.stringify(incident.raw_content)} />
              <AttachmentList list={attachments.filter((a) => a.commentId === null)} />
              {incident.assigned_name && (
                <CardFooter className="assigned-details">
                  <div className="items-detail">
                    <div className="item">
                      <Caption1 style={{ marginBottom: "1em" }}>Assigned to</Caption1>
                      <Persona
                        size="medium"
                        name={incident.assigned_name || "Unassigned"}
                        avatar={{ color: "colorful" }}
                        secondaryText={incident.team_name || ""}
                      />
                    </div>

                    {incident.priority && (
                      <div className="item">
                        <Caption1 style={{ marginBottom: "1em" }}>Priority</Caption1>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CircleFilled
                            className={`dot-priority dot-priority-${incident.priority}`}
                          />
                          <Caption1>{incident.priority}</Caption1>
                        </div>
                      </div>
                    )}
                  </div>
                </CardFooter>
              )}
            </div>
            {comments.length > 0 &&
              comments.map((comment, index) => (
                <Fragment key={index}>
                  <Divider appearance="subtle" />
                  <div style={{ paddingLeft: "0.7em" }}>
                    <CommentReadOnly
                      comment={comment}
                      attachments={attachments.filter((a) => a.commentId === comment.comment_id)}
                      isLoading={comment.isLoading || false}
                    />
                    {errorOnMessage && index === comments.length - 1 && (
                      <div style={{ paddingInline: 25 }}>
                        <MessageGroup messages={[errorOnMessage]} />
                      </div>
                    )}
                  </div>
                </Fragment>
              ))}
            <Divider appearance="subtle" />
            <NewComment
              case_id={incident.case_id}
              onCommentSent={addCommentToList}
              onCommentProcessed={updateCommentStatus}
            />
          </div>
        </Collapse>
      </Card>
    </div>
  );
};
