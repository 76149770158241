import { Caption1, Persona, Caption1Stronger, Spinner } from "@fluentui/react-components";
import { RichEditorReadOnly } from "../../components/RichEditorReadOnly";
import { CompactAttachmentList } from "../../components/CompactAttachmentList";
import { getHumanDate } from "../../lib/common";

export default function CommentReadOnly({ comment, attachments, isLoading = false }) {
  return (
    <div className="flex-column" style={{ gap: "10px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          padding: "10px 5px 10px 0px",
        }}
      >
        <Persona
          size="extra-small"
          name={comment.by_user_name}
          primaryText=""
          avatar={{ color: "colorful" }}
        />
        <div>
          <div style={{ marginBottom: "0.5em", display: "flex" }}>
            <Caption1Stronger>{comment.by_user_name}</Caption1Stronger>
            <Caption1 style={{ paddingInline: 3 }}>·</Caption1>
            <Caption1>{getHumanDate(comment.comment_date)}</Caption1>
            {isLoading && <Spinner size="extra-tiny" style={{ marginLeft: 5 }} />}
          </div>
          <div style={{ background: "var(--colorNeutralBackground1Hover)", borderRadius: "5px" }}>
            <RichEditorReadOnly initialContent={JSON.stringify(comment.raw_comment)} />
          </div>
          {attachments.length > 0 && <CompactAttachmentList list={attachments} />}
        </div>
      </div>
    </div>
  );
}
