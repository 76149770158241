import * as React from "react";
import { DismissRegular } from "@fluentui/react-icons";
import {
  MessageBar,
  MessageBarActions,
  MessageBarTitle,
  MessageBarBody,
  MessageBarGroup,
  Button,
} from "@fluentui/react-components";

export const MessageGroup = ({ messages, animate = true }) => {
  return (
    <MessageBarGroup animate={animate ? "both" : undefined}>
      {messages &&
        messages.map(({ intent, id, title, body, dispatchAction }) => (
          <MessageBar key={`${intent}-${id}`} intent={intent} style={{ marginBottom: "1em" }}>
            <MessageBarBody>
              <MessageBarTitle>{title}</MessageBarTitle>
              {body}
            </MessageBarBody>
            {dispatchAction && (
              <MessageBarActions
                containerAction={
                  <Button
                    onClick={() => dispatchAction(id)}
                    aria-label="dismiss"
                    appearance="transparent"
                    icon={<DismissRegular />}
                  />
                }
              />
            )}
          </MessageBar>
        ))}
    </MessageBarGroup>
  );
};
