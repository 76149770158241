import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogBody,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Field,
  Input,
  Combobox,
  Option,
  Textarea,
} from "@fluentui/react-components";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";

export const TagDetailsDialog = ({ isOpen, onClose, mode, availableCategories, selectedTag }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  const [tagName, setTagName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  // Errors
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorCategory, setShowErrorCategory] = useState(false);

  useEffect(() => {
    if (mode === "edit" && selectedTag) {
      setTagName(selectedTag.value);
      setSelectedCategory(selectedTag.category);
      setTagDescription(selectedTag.description);
    } else {
      if (!isOpen) {
        setTimeout(() => {
          setTagName("");
          setSelectedCategory("");
          setTagDescription("");
          setShowErrorName(false);
          setShowErrorCategory(false);
          setMessages([]);
        }, 500);
      }
    }
  }, [mode, selectedTag, isOpen]);

  const saveTag = async () => {
    let isValidForm = true;
    if (tagName.trim().length === 0) {
      setShowErrorName(true);
      isValidForm = false;
    }
    if (!selectedCategory || selectedCategory.trim().length === 0) {
      setShowErrorCategory(true);
      isValidForm = false;
    }
    if (isValidForm) {
      if (mode === "create") {
        try {
          setLoading(true);
          await axiosUserInstance.post("/org/tags", {
            value: tagName,
            category: selectedCategory,
            description: tagDescription,
          });
          onClose(true);
        } catch (e) {
          const isDuplicatedError = e.response && [409].includes(e.response.status);
          let bodyMsg = isDuplicatedError
            ? e.response?.data["detail"]?.join(",") || "Duplicated tags"
            : `Some unexpected error has occurred and Tag could not be created. ${e.message}. Please, try again later`;

          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: isDuplicatedError ? "warning" : "error",
                title: isDuplicatedError ? "" : "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        } finally {
          setLoading(false);
        }
      } else if (mode === "edit") {
        try {
          await axiosUserInstance.put("/org/tags", {
            id: selectedTag.id,
            value: tagName,
            category: selectedCategory,
            description: tagDescription,
          });
          onClose(true);
        } catch (e) {
          let bodyMsg = `Some unexpected error has occurred and Tag could not be updated. ${e.message}. Please, try again later`;
          if (!messages.some((m) => m.body === bodyMsg)) {
            setMessages((prevMessages) => [
              {
                id: generateRandomId(8),
                intent: "error",
                title: "Oops!",
                body: bodyMsg,
                dispatchAction: (msgId) =>
                  setMessages((s) => s.filter((entry) => entry.id !== msgId)),
              },
              ...prevMessages,
            ]);
          }
        }
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{mode === "create" ? "Add new Tag" : "Edit Tag"}</DialogTitle>
          <DialogContent>
            <MessageGroup messages={messages} />
            <div style={{ marginBottom: "4em", display: "grid", gap: "0.5em" }}>
              <Field
                label="Name"
                required
                validationMessage={showErrorName ? "Specify a name for the tag" : ""}
              >
                <Input
                  placeholder="e.g., Security"
                  value={tagName}
                  onChange={(event) => {
                    setTagName(event.target.value);
                    if (showErrorName && event.target.value.trim().length > 0)
                      setShowErrorName(false);
                  }}
                />
              </Field>
              <Field
                label="Category"
                required
                validationMessage={showErrorCategory ? "Specify a category for the tag" : ""}
              >
                {availableCategories.length > 0 ? (
                  <Combobox
                    onOptionSelect={(e, data) => setSelectedCategory(data.optionText)}
                    placeholder="Enter or select a category"
                    onChange={(ev) => {
                      setSelectedCategory(ev.target.value);
                      if (showErrorCategory && ev.target.value.trim().length > 0)
                        setShowErrorCategory(false);
                    }}
                    value={selectedCategory}
                  >
                    {availableCategories.map((category) => (
                      <Option key={category}>{category}</Option>
                    ))}
                  </Combobox>
                ) : (
                  <Input
                    placeholder="e.g., Software"
                    value={selectedCategory}
                    onChange={(event) => {
                      setSelectedCategory(event.target.value);
                      if (showErrorCategory && event.target.value.trim().length > 0)
                        setShowErrorCategory(false);
                    }}
                  />
                )}
              </Field>

              <Field label="Description">
                <Textarea
                  value={tagDescription}
                  maxLength={5000}
                  onChange={(event) => {
                    setTagDescription(event.target.value);
                  }}
                />
              </Field>
            </div>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button appearance="primary" disabled={loading} onClick={saveTag}>
              {mode === "create" ? "Create" : "Update"}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
