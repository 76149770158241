import { useEffect, useState } from "react";
import { Card, CardHeader, Caption1, Button, Text } from "@fluentui/react-components";
import { Collapse } from "@fluentui/react-motion-components-preview";
import { DeleteRegular } from "@fluentui/react-icons";
import { getFileType } from "../../../lib/common";

export function AttachedFilesBar({ visible, selectedFiles, onDeleteItem }) {
  const [delayVisible, setDelayVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDelayVisible(visible);
    }, 80);
  }, [visible]);

  return (
    <Collapse visible={visible ? delayVisible : false}>
      <div
        style={{
          display: "grid",
          gap: "10px",
          gridArea: "card",
          padding: visible ? "10px" : "0px",
        }}
      >
        {selectedFiles.map(({ name, size }) => (
          <Card
            key={name}
            className={{
              height: "fit-content",
            }}
            size="small"
            role="listitem"
          >
            <CardHeader
              image={{ as: "img", src: `/icons/${getFileType(name)}.png`, alt: name }}
              header={<Text weight="semibold">{name}</Text>}
              description={
                <Caption1 style={{ color: "var(--colorNeutralForeground3)" }}>{size}</Caption1>
              }
              action={
                <Button
                  appearance="transparent"
                  icon={<DeleteRegular style={{ color: "var(--colorPaletteRedForeground1)" }} />}
                  onClick={() => onDeleteItem(name)}
                />
              }
            />
          </Card>
        ))}
      </div>
    </Collapse>
  );
}
