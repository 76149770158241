import React from "react";
import { Chrono } from "react-chrono";
import { getShortDate, getFullDateTime } from "../../lib/common";

const transformHistoryToChronoItems = (history) => {
  return history.map((item) => ({
    title: getShortDate(item.change_date),
    cardTitle: item.change_action,
    cardSubtitle: `${item.change_summary} by: ${item.by_user_name}`,
    cardDetailedText: getFullDateTime(item.change_date),
  }));
};

const Timeline = ({ history }) => {
  const items = transformHistoryToChronoItems(history);

  return (
    <Chrono
      items={items}
      mode="VERTICAL"
      hideControls={true}
      cardHeight={100}
      fontSizes={{
        cardTitle: "0.85rem",
        cardSubtitle: "0.8rem",
      }}
      theme={{
        cardBgColor: "var(--colorNeutralBackground1Hover)",
        cardTitleColor: "var(--colorNeutralForeground1)",
        cardSubtitleColor: "var(--colorNeutralForeground1)",
        cardDetailsColor: "var(--colorNeutralForeground2)",
        secondary: "var(--colorPaletteBerryBorder2)", // Active date background
        titleColorActive: "var(--colorNeutralForegroundStaticInverted)", // Active date foreground
        primary: "var(--colorPaletteBerryBorder1)", //line
        titleColor: "var(--colorPaletteBerryBorder1)", // past date
      }}
    />
  );
};

export default Timeline;
