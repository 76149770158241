import { Card, CardHeader, Caption1, Text } from "@fluentui/react-components";
import { Open20Regular } from "@fluentui/react-icons";
import { getFileType, formatFileSize, shortenFileName } from "../../lib/common";
import { app } from "@microsoft/teams-js";

export function AttachmentList({ list }) {
  if (list.length === 0) return;
  return (
    <div className="attachment-list">
      {list.map(({ fileName, size, url }) => (
        <Card
          appearance="outline"
          key={fileName}
          className="attachment-container"
          size="small"
          role="listitem"
          onClick={() => app.openLink(url)}
        >
          <CardHeader
            image={{
              as: "img",
              src: `/icons/${getFileType(fileName)}.png`,
              alt: fileName,
            }}
            header={<Text weight="semibold">{shortenFileName(fileName, 50)}</Text>}
            description={
              <Caption1 style={{ color: "var(--colorNeutralForeground3)" }}>
                {formatFileSize(size)}
              </Caption1>
            }
            action={<Open20Regular className="attachment-icon-link" />}
          />
        </Card>
      ))}
    </div>
  );
}
