export class FileUploader {
  constructor(onProgress) {
    this.CHUNK_SIZE = 327680; // Exactamente 320 KiB
    this.MAX_SIMPLE_UPLOAD_SIZE = 4 * 1024 * 1024; // 4MB
    this.onProgress = onProgress || (() => {});
    this.progress = [];
  }

  updateProgress(fileName, update) {
    const index = this.progress.findIndex((p) => p.fileName === fileName);
    if (index >= 0) {
      this.progress[index] = { ...this.progress[index], ...update };
    } else {
      this.progress.push({
        fileName,
        progress: 0,
        status: "pending",
        ...update,
      });
    }
    this.onProgress(this.progress);
  }

  async uploadLargeFile(file, uploadUrl) {
    const totalChunks = Math.ceil(file.size / this.CHUNK_SIZE);
    let fileData = null;

    for (let chunk = 0; chunk < totalChunks; chunk++) {
      const start = chunk * this.CHUNK_SIZE;
      const end = Math.min(start + this.CHUNK_SIZE, file.size);
      const contentLength = end - start;

      const fileChunk = file.slice(start, end);

      try {
        const response = await fetch(uploadUrl, {
          method: "PUT",
          headers: {
            "Content-Length": contentLength.toString(),
            "Content-Range": `bytes ${start}-${end - 1}/${file.size}`,
            "Content-Type": "application/octet-stream",
          },
          body: fileChunk,
        });

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Upload error details:", errorText);
          throw new Error(`Failed to upload chunk: ${errorText}`);
        }

        // Verificar si es una respuesta 202 (más chunks por enviar) o 201/200 (completado)
        const responseData = await response.json();
        // Si es el último chunk (respuesta 200 o 201), guardar la información del archivo
        if (response.status === 200 || response.status === 201) {
          fileData = responseData;
        }

        this.updateProgress(file.name, {
          progress: ((chunk + 1) / totalChunks) * 100,
          status: "uploading",
          additionalInfo: `Chunk ${chunk + 1} of ${totalChunks}`,
        });
      } catch (error) {
        console.error(`Error uploading chunk ${chunk + 1}/${totalChunks}:`, error);
        throw error;
      }
    }

    if (fileData) {
      return {
        id: fileData.id,
        webUrl: fileData.webUrl,
      };
    } else {
      throw new Error("File upload completed but file information not received");
    }
  }

  async uploadSimpleFile(file, uploadUrl) {
    try {
      const response = await fetch(uploadUrl, {
        method: "PUT",
        headers: {
          "Content-Length": file.size.toString(),
          "Content-Range": `bytes 0-${file.size - 1}/${file.size}`,
          "Content-Type": "application/octet-stream",
        },
        body: file,
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Upload failed: ${errorText}`);
      }

      const fileData = await response.json();
      return {
        id: fileData.id,
        webUrl: fileData.webUrl,
      };
    } catch (error) {
      console.error("Error in simple upload:", error);
      throw error;
    }
  }

  async uploadFiles(selectedFiles, uploadSessions) {
    const results = [];

    for (const session of uploadSessions) {
      const fileData = selectedFiles.find((f) => f.name === session.fileName);
      if (!fileData) continue;

      try {
        let uploadResult;
        if (fileData.file.size > this.MAX_SIMPLE_UPLOAD_SIZE) {
          uploadResult = await this.uploadLargeFile(fileData.file, session.uploadUrl);
        } else {
          uploadResult = await this.uploadSimpleFile(fileData.file, session.uploadUrl);
        }

        results.push({
          fileName: fileData.name,
          status: "success",
          id: uploadResult.id,
          webUrl: uploadResult.webUrl,
          size: fileData.file.size,
        });
      } catch (error) {
        results.push({
          fileName: fileData.name,
          status: "error",
          error: error.message,
        });
      }
    }

    return results;
  }
}
