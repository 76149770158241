import React, { useState } from "react";
import { Image, Subtitle2, Body1 } from "@fluentui/react-components";

const AnnouncementSmall = ({
  imgSrc,
  imgAlt,
  imgWidth = "15em",
  title,
  subtitle,
  actionComponent,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div
      style={{
        visibility: isLoaded ? "visible" : "hidden",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Image
        src={imgSrc}
        alt={imgAlt}
        style={{ maxWidth: imgWidth, opacity: "0.9", marginRight: "1em" }}
        onLoad={handleImageLoad}
      />
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        {title && <Subtitle2>{title}</Subtitle2>}
        <Body1 style={{ paddingBottom: "3em", color: "var(--colorNeutralForeground4)" }}>
          {subtitle}
        </Body1>
        {actionComponent}
      </div>
    </div>
  );
};

export default AnnouncementSmall;
