export const availableFilters = {
  assigned: [
    { key: "my-teams", display: "My teams" },
    { key: "mine", display: "Mine" },
    { key: "unassigned", display: "Unassigned" },
  ],
  status: [
    { key: "open", display: "Open" },
    { key: "on-hold", display: "On hold" },
    { key: "completed", display: "Completed" },
    { key: "canceled", display: "Canceled" },
  ],
  priority: [
    { key: "normal", display: "Normal" },
    { key: "moderate", display: "Moderate" },
    { key: "high", display: "High" },
    { key: "critical", display: "Critical" },
  ],
  type: [
    { key: "incident", display: "Incident" },
    { key: "request", display: "Request" },
  ],
};
