import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $wrapNodes, $isAtNodeEnd } from "@lexical/selection";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  $isListNode,
  ListNode,
} from "@lexical/list";
import { createPortal } from "react-dom";
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from "@lexical/rich-text";
import {
  $createCodeNode,
  $isCodeNode,
  getDefaultCodeLanguage,
  getCodeLanguages,
} from "@lexical/code";
import {
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Button,
  Select,
  ToggleButton,
  ToolbarDivider,
  Tooltip,
} from "@fluentui/react-components";
import {
  TextDescriptionLtrFilled,
  TextBulletListFilled,
  TextNumberListLtrFilled,
  TextCaseTitleFilled,
  TextQuoteFilled,
  CodeFilled,
  ArrowHookUpLeftFilled,
  ArrowHookUpRightFilled,
  TextMoreRegular,
  TextBoldRegular,
  TextItalicRegular,
  TextUnderlineRegular,
  TextStrikethroughRegular,
  CodeRegular,
  LinkRegular,
  CollectionsAddRegular as AddMediaIcon,
  AttachFilled as AttachIcon,
} from "@fluentui/react-icons";

const LowPriority = 1;

const supportedBlockTypes = new Set(["paragraph", "quote", "code", "h1", "ul", "ol"]);

const blockTypeToBlockName = {
  code: "Code",
  h1: "Heading",
  h2: "Small Heading",
  h3: "Heading",
  h4: "Heading",
  h5: "Heading",
  ol: "Numbered",
  paragraph: "Normal",
  quote: "Quote",
  ul: "Bulleted",
};

function positionEditorElement(editor, rect) {
  if (rect === null) {
    editor.style.opacity = "0";
    editor.style.top = "-1000px";
    editor.style.left = "-1000px";
  } else {
    editor.style.opacity = "1";
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
    }px`;
  }
}

function FloatingLinkEditor({ editor }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState("");
  const [isEditMode, setEditMode] = useState(true);
  const [lastSelection, setLastSelection] = useState(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl("");
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== "link-input") {
      positionEditorElement(editorElem, null);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        LowPriority
      )
    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className="link-editor">
      {isEditMode ? (
        <input
          ref={inputRef}
          className="link-input"
          value={linkUrl}
          onChange={(event) => {
            setLinkUrl(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              if (lastSelection !== null) {
                if (linkUrl !== "") {
                  editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                }
                setEditMode(false);
              }
            } else if (event.key === "Escape") {
              event.preventDefault();
              setEditMode(false);
            }
          }}
        />
      ) : (
        <>
          <div className="link-input">
            {linkUrl && (
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </a>
            )}
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

function getSelectedNode(selection) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export default function ToolbarPlugin({
  incidentType,
  onIncidentTypeChange,
  onCancel,
  onSend,
  displayUndoRedoBtns = true,
  onMediaClick,
  mediaItemSelectedCount,
}) {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [isEditorEmpty, setIsEditorEmpty] = useState(true);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [selectedElementKey, setSelectedElementKey] = useState(null);
  const [codeLanguage, setCodeLanguage] = useState("");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root" ? anchorNode : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType();
          setBlockType(type);
          if ($isCodeNode(element)) {
            setCodeLanguage(element.getLanguage() || getDefaultCodeLanguage());
          }
        }
      }
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));
      setIsCode(selection.hasFormat("code"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot();
        const children = root.getChildren();

        // Verifica si el editor está vacío
        const isEmpty =
          children.length === 0 ||
          (children.length === 1 &&
            children[0].getType() === "paragraph" &&
            children[0].getTextContent().trim() === "");

        setIsEditorEmpty(isEmpty);
      });
    });
  }, [editor]);

  const codeLanguges = useMemo(() => getCodeLanguages(), []);
  const onCodeLanguageSelect = useCallback(
    (e) => {
      editor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey);
          if ($isCodeNode(node)) {
            node.setLanguage(e.target.value);
          }
        }
      });
    },
    [editor, selectedElementKey]
  );

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  function getBlockIcon(block) {
    switch (block) {
      case "paragraph":
        return <TextDescriptionLtrFilled />;
      case "ul":
        return <TextBulletListFilled />;
      case "ol":
        return <TextNumberListLtrFilled />;
      case "quote":
        return <TextQuoteFilled />;
      case "h1":
        return <TextCaseTitleFilled />;
      case "code":
        return <CodeFilled />;
      default:
        return <TextDescriptionLtrFilled />;
    }
  }

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode("h1"));
        }
      });
    }
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
  };

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
  };

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createCodeNode());
        }
      });
    }
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      <div className="two-part-tools">
        {incidentType && (
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <MenuButton appearance="subtle" size="small">
                <Image src={`/icons/${incidentType}48.png`} style={{ width: "20px" }} />
              </MenuButton>
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem
                  data-value="incident"
                  icon={<Image src="/icons/incident48.png" style={{ width: "16px" }} />}
                  onClick={onIncidentTypeChange}
                >
                  Incident
                </MenuItem>
                <MenuItem
                  data-value="request"
                  icon={<Image src="/icons/request48.png" style={{ width: "22px" }} />}
                  onClick={onIncidentTypeChange}
                >
                  Request
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        )}
        {displayUndoRedoBtns && (
          <>
            <Button
              appearance="subtle"
              disabled={!canUndo}
              onClick={() => {
                editor.dispatchCommand(UNDO_COMMAND);
              }}
              icon={<ArrowHookUpLeftFilled />}
            />
            <Button
              appearance="subtle"
              disabled={!canRedo}
              onClick={() => {
                editor.dispatchCommand(REDO_COMMAND);
              }}
              icon={<ArrowHookUpRightFilled />}
            />
          </>
        )}
        <div className="flex-row">
          {supportedBlockTypes.has(blockType) && (
            <Menu>
              <MenuTrigger disableButtonEnhancement>
                <MenuButton appearance="subtle" icon={getBlockIcon(blockType)}>
                  <span>{blockTypeToBlockName[blockType]}</span>
                </MenuButton>
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem icon={<TextDescriptionLtrFilled />} onClick={formatParagraph}>
                    Normal
                  </MenuItem>
                  <MenuItem icon={<TextBulletListFilled />} onClick={formatBulletList}>
                    Bulleted List
                  </MenuItem>
                  <MenuItem icon={<TextNumberListLtrFilled />} onClick={formatNumberedList}>
                    Numbered List
                  </MenuItem>
                  <MenuItem icon={<TextCaseTitleFilled />} onClick={formatLargeHeading}>
                    Heading
                  </MenuItem>
                  <MenuItem icon={<TextQuoteFilled />} onClick={formatQuote}>
                    Quote
                  </MenuItem>
                  <MenuItem icon={<CodeFilled />} onClick={formatCode}>
                    Code Block
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          )}
        </div>
        <div className="flex-row">
          {blockType === "code" ? (
            <Select
              id={codeLanguage}
              appearance="filled-lighter"
              onChange={onCodeLanguageSelect}
              value={codeLanguage}
            >
              {codeLanguges.map((opt) => (
                <option key={opt}>{opt}</option>
              ))}
            </Select>
          ) : (
            <>
              <div className="responsive-menus-toolbar">
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <MenuButton appearance="subtle" icon={<TextMoreRegular />} />
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        icon={<TextBoldRegular className="toolbar-icon" />}
                        onClick={() => {
                          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                        }}
                      >
                        Bold
                      </MenuItem>
                      <MenuItem
                        icon={<TextItalicRegular className="toolbar-icon" />}
                        onClick={() => {
                          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                        }}
                      >
                        Italic
                      </MenuItem>
                      <MenuItem
                        icon={<TextUnderlineRegular className="toolbar-icon" />}
                        onClick={() => {
                          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                        }}
                      >
                        Underline
                      </MenuItem>
                      <MenuItem
                        icon={<TextStrikethroughRegular className="toolbar-icon" />}
                        onClick={() => {
                          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                        }}
                      >
                        Strikethrough
                      </MenuItem>
                      <MenuItem
                        icon={<CodeRegular className="toolbar-icon" />}
                        onClick={() => {
                          editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                        }}
                      >
                        Code snippet
                      </MenuItem>
                      <MenuItem
                        icon={<LinkRegular className="toolbar-icon" />}
                        onClick={insertLink}
                      >
                        Link
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
              <div className="responsive-buttons-toolbar">
                <Tooltip withArrow showDelay={1500} content="Bold" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={() => {
                      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                    }}
                    icon={<TextBoldRegular className="toolbar-icon" />}
                    checked={isBold}
                  />
                </Tooltip>
                <Tooltip withArrow showDelay={1500} content="Italic" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={() => {
                      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                    }}
                    icon={<TextItalicRegular className="toolbar-icon" />}
                    checked={isItalic}
                  />
                </Tooltip>
                <Tooltip withArrow showDelay={1500} content="Underline" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={() => {
                      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                    }}
                    icon={<TextUnderlineRegular className="toolbar-icon" />}
                    checked={isUnderline}
                  />
                </Tooltip>
                <Tooltip withArrow showDelay={1500} content="Strikethrough" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={() => {
                      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                    }}
                    icon={<TextStrikethroughRegular className="toolbar-icon" />}
                    checked={isStrikethrough}
                  />
                </Tooltip>
                <Tooltip withArrow showDelay={1500} content="Code snippet" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={() => {
                      editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                    }}
                    icon={<CodeRegular className="toolbar-icon" />}
                    checked={isCode}
                  />
                </Tooltip>
                <Tooltip withArrow showDelay={1500} content="Link" relationship="label">
                  <ToggleButton
                    appearance="subtle"
                    onClick={insertLink}
                    icon={<LinkRegular className="toolbar-icon" />}
                    checked={isLink}
                  />
                </Tooltip>
              </div>
              {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
            </>
          )}

          {onMediaClick && (
            <>
              <ToolbarDivider />
              <Tooltip
                withArrow
                showDelay={1500}
                content={mediaItemSelectedCount > 0 ? "Upload more documents" : "Upload documents"}
                relationship="label"
              >
                <Button
                  appearance="subtle"
                  onClick={onMediaClick}
                  icon={
                    mediaItemSelectedCount > 0 ? (
                      <AddMediaIcon className="toolbar-icon" />
                    ) : (
                      <AttachIcon className="toolbar-icon" />
                    )
                  }
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <div className="flex-row" style={{ width: "fit-content" }}>
        <Button appearance="transparent" onClick={onCancel} style={{ marginRight: "0.5em" }}>
          Cancel
        </Button>
        <Button appearance="primary" disabled={isEditorEmpty} onClick={onSend}>
          Send
        </Button>
      </div>
    </div>
  );
}
