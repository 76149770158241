import { useState } from "react";
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  ProgressBar,
} from "@fluentui/react-components";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential, useData } from "@microsoft/teamsfx-react";
import { authentication } from "@microsoft/teams-js";
import Tab from "./pages/Tab";
import { TeamsFxContext } from "./Context";
import config from "./lib/config";
import { generateHashFromMstToken } from "./lib/common";
import useAxios from "./lib/useAxios";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const {
    loading: credentialLoading,
    themeString,
    teamsUserCredential,
  } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint,
    clientId: config.clientId,
  });

  const { axiosInstance: axiosHubInstance } = useAxios(config.hubEndpoint, false);

  const [backendLoading, setBackendLoading] = useState(true);
  const [organizationConfig, setOrganizationConfig] = useState();

  useData(async () => {
    try {
      const teamsUserToken = await authentication.getAuthToken();
      const { data } = await axiosHubInstance.post("/auth/msteams/config", {
        mst_token: teamsUserToken,
        mst_hash: generateHashFromMstToken(teamsUserToken),
      });
      setOrganizationConfig({
        organizationName: data.name,
        organizationApiUrl: data.api_url,
        organizationStatus: data.organization_status,
      });
    } catch (e) {
      console.debug(e);
      setOrganizationConfig(null);
    } finally {
      setBackendLoading(false);
    }
  });

  const updateOrganizationName = (newName) => {
    setOrganizationConfig((prev) => ({
      ...prev,
      organizationName: newName,
    }));
  };

  const updateOrganizationStatus = (newStatus, orgUrl) => {
    setOrganizationConfig((prev) => ({
      ...prev,
      organizationStatus: newStatus,
      organizationApiUrl: orgUrl,
    }));
  };

  const loading = credentialLoading || backendLoading;

  return (
    <TeamsFxContext.Provider
      value={{
        themeString,
        teamsUserCredential,
        organizationName: organizationConfig?.organizationName,
        organizationApiUrl: organizationConfig?.organizationApiUrl,
        organizationStatus: organizationConfig?.organizationStatus,
        updateOrganizationName,
        updateOrganizationStatus,
      }}
    >
      <FluentProvider
        style={{ backgroundColor: "transparent" }}
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
            ? teamsHighContrastTheme
            : {
                ...teamsLightTheme,
              }
        }
      >
        <Router>
          {loading ? (
            <ProgressBar />
          ) : (
            <Routes>
              <Route path="/tab/*" element={<Tab />} />
              <Route path="*" element={<Navigate to={"/tab"} />}></Route>
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
