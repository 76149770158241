import React from "react";
import { createRoot } from "react-dom/client";
import { app } from "@microsoft/teams-js";
import App from "./app/App";
import "./index.css";

const initialize = async () => {
  try {
    const isInTeams = window.parent !== window.self;
    if (!isInTeams) {
      const container = document.getElementById("root");
      const root = createRoot(container);
      root.render(
        <div style={{ padding: 20 }}>This application is designed to run in Microsoft Teams</div>
      );
      return;
    }

    await app.initialize();
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(<App />);
  } catch (error) {
    console.error("Failed to initialize:", error);
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
      <div style={{ padding: 20, color: "red" }}>
        Error initializing Teams application: {error.message}
      </div>
    );
  }
};

// Start initialization
initialize();
