import { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  Button,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { MessageGroup } from "../../../../components/MessageGroup";
import { generateRandomId } from "../../../../lib/common";
import { TeamsFxContext } from "../../../../Context";
import useAxios from "../../../../lib/useAxios";

export const TagMergeDialog = ({ isOpen, onClose, toMergeTags }) => {
  const { organizationApiUrl } = useContext(TeamsFxContext);
  const { axiosInstance: axiosUserInstance } = useAxios(organizationApiUrl);

  const [messages, setMessages] = useState([]);
  const [prevailingTagId, setPrevailingTagId] = useState();

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setPrevailingTagId();
        setMessages([]);
      }, 500);
    }
  }, [toMergeTags]);

  const handleMergeAction = async () => {
    try {
      const secondary_tags = toMergeTags.filter((e) => e.id !== prevailingTagId).map((m) => m.id);
      await axiosUserInstance.put("/org/tags/merge", {
        main_id: prevailingTagId,
        secondary_ids: secondary_tags,
      });
      onClose(true);
    } catch (e) {
      const bodyMsg = `Some unexpected error has occurred and Tags could not be merged. ${e.message}. Please, try again later`;
      if (!messages.some((m) => m.body === bodyMsg)) {
        setMessages((prevMessages) => [
          {
            id: generateRandomId(8),
            intent: "error",
            title: "Oops!",
            body: bodyMsg,
            dispatchAction: (msgId) => setMessages((s) => s.filter((entry) => entry.id !== msgId)),
          },
          ...prevMessages,
        ]);
      }
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogSurface>
        <MessageGroup messages={messages} />
        <DialogBody>
          <DialogTitle>Merging Tags</DialogTitle>
          <DialogContent>
            <p>
              You are about to merge a set of tags. Choose which one will prevail over the others;
              all other tags will be merged into this one.
            </p>
            <p>
              <MessageGroup
                animate={false}
                messages={[
                  {
                    id: "3333",
                    intent: "warning",
                    title: "Warning:",
                    body: "this action cannot be undone and could affect the tags assigned to some incidents.",
                  },
                ]}
              />
            </p>
            <Dropdown
              placeholder="Select the prevailing tag"
              onOptionSelect={(e, data) => setPrevailingTagId(data.optionValue)}
            >
              {toMergeTags.map((tag) => (
                <Option key={tag.id} value={tag.id}>
                  {tag.value}
                </Option>
              ))}
            </Dropdown>
          </DialogContent>
          <DialogActions>
            <Button appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!prevailingTagId} appearance="primary" onClick={handleMergeAction}>
              Merge Tags
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
