import { useRef } from "react";
import {
  Persona,
  createTableColumn,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  Menu,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItem,
  Image,
  Tooltip,
  Tag,
  TableCellLayout,
} from "@fluentui/react-components";
import { CircleFilled, Attach16Regular } from "@fluentui/react-icons";
import { getHumanDate } from "../../../../lib/common";

const columnSizingOptions = {
  subject: {
    minWidth: 120,
    defaultWidth: 380,
  },
  requested: {
    minWidth: 200,
    defaultWidth: 220,
  },
  assigned: {
    minWidth: 200,
    defaultWidth: 220,
  },
  id: {
    minWidth: 160,
    defaultWidth: 220,
  },
  priority: {
    minWidth: 100,
    defaultWidth: 180,
  },
  lastUpdated: {
    minWidth: 120,
    defaultWidth: 180,
  },
};

const fullColumns = [
  createTableColumn({
    columnId: "subject",
    compare: (a, b) => {
      return a.subject.localeCompare(b.subject);
    },
    renderHeaderCell: () => {
      return "Subject";
    },
    renderCell: (item) => {
      return (
        <div style={{ display: "flex" }}>
          <span style={{ padding: "15px" }}>
            <Tooltip
              content={<span className="first-letter">{item.classification}</span>}
              withArrow={true}
              relationship="label"
            >
              <Image
                src={`/icons/${item.classification}${item.status}48.png`}
                style={{ width: "24px" }}
              />
            </Tooltip>
          </span>
          <TableCellLayout>{item.subject}</TableCellLayout>
        </div>
      );
    },
    sizingOptions: columnSizingOptions.subject,
  }),
  createTableColumn({
    columnId: "requested",
    compare: (a, b) => {
      return a.requester_name?.localeCompare(b.requester_name);
    },
    renderHeaderCell: () => {
      return "Requested by";
    },
    renderCell: (item) => {
      return (
        <Tooltip content={item.requester_email} withArrow={true} relationship="label">
          <TableCellLayout>
            <Persona
              size="medium"
              name={item.requester_name}
              avatar={{ color: "colorful" }}
              secondaryText={`@${item.requester_email?.split("@")[0]}`}
            />
          </TableCellLayout>
        </Tooltip>
      );
    },
    sizingOptions: columnSizingOptions.requested,
  }),
  createTableColumn({
    columnId: "assigned",
    compare: (a, b) => {
      return a.assigned_name?.localeCompare(b.assigned_name);
    },
    renderHeaderCell: () => {
      return "Assigned to";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          <Persona
            size="medium"
            name={item.assigned_name || "Unassigned"}
            avatar={{ color: "colorful" }}
            secondaryText={item.team_name || "-"}
          />
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.assigned,
  }),
  createTableColumn({
    columnId: "id",
    compare: (a, b) => {
      return a.case_id.localeCompare(b.case_id);
    },
    renderHeaderCell: () => {
      return "Case #";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout className="caseId">
          {item.case_id}
          {" • "}
          <span
            shape="circular"
            size="small"
            appearance="brand"
            className={`tag-status-${item.status} pill-tag`}
          >
            {item.status}
          </span>{" "}
          {item.have_attachment && <Attach16Regular className="attachmentIconItem" />}
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.id,
  }),
  createTableColumn({
    columnId: "priority",
    compare: (a, b) => {
      return a.priority.localeCompare(b.priority);
    },
    renderHeaderCell: () => {
      return "Priority";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CircleFilled className={`dot-priority dot-priority-${item.priority}`} />
            <span>{item.priority}</span>
          </div>
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.priority,
  }),
  createTableColumn({
    columnId: "lastUpdated",
    compare: (a, b) => {
      return new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime();
    },
    renderHeaderCell: () => {
      return "Last updated";
    },
    renderCell: (item) => {
      return getHumanDate(item.updated_at);
    },
    sizingOptions: columnSizingOptions.lastUpdated,
  }),
];

const columnsForShortVersion = ["subject", "requested", "lastUpdated"];

export const AssignedDataList = ({ list, onSelectRow, setShowLessColumns, showLessColums }) => {
  const refMap = useRef({});

  const lessColumns = fullColumns.filter((column) =>
    columnsForShortVersion.includes(column.columnId)
  );

  return (
    <DataGrid
      items={list}
      columns={showLessColums === true ? lessColumns : fullColumns}
      sortable
      getRowId={(item) => item.case_id}
      selectionMode="single"
      data-show-checkboxes="false"
      onSelectionChange={(e, row) => {
        onSelectRow(row);
        setShowLessColumns(true);
      }}
      resizableColumns
      columnSizingOptions={columnSizingOptions}
      className="scrollable-content"
      style={{
        maxHeight: "500px",
        padding: "10px",
      }}
    >
      <DataGridHeader>
        <DataGridRow
          selectionCell={{
            checkboxIndicator: { "aria-label": "Select all rows" },
          }}
        >
          {({ renderHeaderCell, columnId }, dataGrid) =>
            dataGrid.resizableColumns ? (
              <Menu openOnContext>
                <MenuTrigger>
                  <DataGridHeaderCell ref={(el) => (refMap.current[columnId] = el)}>
                    {renderHeaderCell()}
                  </DataGridHeaderCell>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    <MenuItem onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}>
                      Keyboard Column Resizing
                    </MenuItem>
                  </MenuList>
                </MenuPopover>
              </Menu>
            ) : (
              <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
            )
          }
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody>
        {({ item, rowId }) => (
          <DataGridRow key={rowId} style={{ cursor: "pointer" }}>
            {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  );
};
