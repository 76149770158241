import { useRef, useMemo } from "react";
import {
  TagRegular,
  TagErrorRegular,
  New20Filled,
  MoreHorizontalFilled,
  bundleIcon,
  Edit20Filled,
  Edit20Regular,
  Delete20Filled,
  Delete20Regular,
} from "@fluentui/react-icons";
import {
  Card,
  Button,
  Body1,
  Divider,
  SearchBox,
  DataGridBody,
  DataGridRow,
  DataGrid,
  DataGridHeader,
  DataGridHeaderCell,
  DataGridCell,
  TableCellLayout,
  createTableColumn,
  TableCellActions,
  Menu,
  MenuTrigger,
  MenuPopover,
  MenuList,
  MenuItem,
  Tooltip,
} from "@fluentui/react-components";
import { getHumanDate } from "../../../../lib/common";

const columnSizingOptions = {
  value: {
    minWidth: 80,
    defaultWidth: 250,
  },
  category: {
    minWidth: 80,
    defaultWidth: 250,
    idealWidth: 250,
  },
  description: {
    minWidth: 50,
    defaultWidth: 250,
    idealWidth: 300,
  },
  created_at: {
    minWidth: 80,
    defaultWidth: 150,
    idealWidth: 150,
  },
};

const EditIcon = bundleIcon(Edit20Filled, Edit20Regular);
const DeleteIcon = bundleIcon(Delete20Filled, Delete20Regular);

const createColumns = (onEditTag, onDeleteTag) => [
  createTableColumn({
    columnId: "value",
    compare: (a, b) => {
      return a.value.localeCompare(b.value);
    },
    renderHeaderCell: () => {
      return "Name";
    },
    renderCell: (item) => {
      return (
        <TableCellLayout
          media={
            getHumanDate(item.created_at) === "just now" ? (
              <New20Filled />
            ) : item.category === null ? (
              <Tooltip withArrow content="Tag without category" relationship="label">
                <TagErrorRegular />
              </Tooltip>
            ) : (
              <TagRegular />
            )
          }
        >
          {item.value}
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.value,
  }),
  createTableColumn({
    columnId: "category",
    compare: (a, b) => {
      return a.category?.localeCompare(b.category);
    },
    renderHeaderCell: () => {
      return "Category";
    },
    renderCell: (item) => {
      return <TableCellLayout truncate>{item.category}</TableCellLayout>;
    },
    sizingOptions: columnSizingOptions.category,
  }),
  createTableColumn({
    columnId: "description",
    compare: (a, b) => {
      return a.description?.localeCompare(b.description);
    },
    renderHeaderCell: () => {
      return "Description";
    },
    renderCell: (item) => {
      return (
        <Tooltip content={item.description} withArrow={true}>
          <TableCellLayout truncate>{item.description}</TableCellLayout>
        </Tooltip>
      );
    },
    sizingOptions: columnSizingOptions.description,
  }),
  createTableColumn({
    columnId: "created_at",
    compare: (a, b) => {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    },
    renderHeaderCell: () => {
      return "Created";
    },

    renderCell: (item) => {
      return (
        <TableCellLayout truncate>
          {getHumanDate(item.created_at)}
          <TableCellActions>
            <Menu>
              <MenuTrigger>
                <Button appearance="subtle" aria-label="more" icon={<MoreHorizontalFilled />} />
              </MenuTrigger>
              <MenuPopover>
                <MenuList>
                  <MenuItem icon={<EditIcon />} onClick={() => onEditTag(item)}>
                    Edit Tag
                  </MenuItem>
                  <MenuItem icon={<DeleteIcon />} onClick={() => onDeleteTag(item)}>
                    Delete Tag
                  </MenuItem>
                </MenuList>
              </MenuPopover>
            </Menu>
          </TableCellActions>
        </TableCellLayout>
      );
    },
    sizingOptions: columnSizingOptions.created_at,
  }),
];

export const TagsDataGrid = ({
  list,
  actionComponent,
  onEditTag,
  onDeleteTag,
  onSearch,
  isInMergeMode,
  onSelectionChange,
}) => {
  const refMap = useRef({});
  const columns = useMemo(() => createColumns(onEditTag, onDeleteTag), [onEditTag, onDeleteTag]);

  return (
    <Card>
      <div className="flex-row">
        {actionComponent}
        <SearchBox
          appearance="filled-darker"
          placeholder="Search"
          onChange={(_, data) => onSearch(data.value)}
        />
      </div>
      <Divider />
      <DataGrid
        items={list}
        columns={columns}
        sortable
        data-show-checkboxes={isInMergeMode.toString()}
        selectionMode={isInMergeMode ? "multiselect" : undefined}
        onSelectionChange={onSelectionChange}
        getRowId={(item) => item.id}
        resizableColumns
        columnSizingOptions={columnSizingOptions}
        resizableColumnsOptions={{
          autoFitColumns: false,
        }}
      >
        <DataGridHeader>
          <DataGridRow
            selectionCell={{
              checkboxIndicator: { "aria-label": "Select all rows" },
            }}
          >
            {({ renderHeaderCell, columnId }, dataGrid) =>
              dataGrid.resizableColumns ? (
                <Menu openOnContext>
                  <MenuTrigger>
                    <DataGridHeaderCell ref={(el) => (refMap.current[columnId] = el)}>
                      {renderHeaderCell()}
                    </DataGridHeaderCell>
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        onClick={dataGrid.columnSizing_unstable.enableKeyboardMode(columnId)}
                      >
                        Keyboard Column Resizing
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              ) : (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )
            }
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody>
          {({ item, rowId }) => (
            <DataGridRow
              key={rowId}
              selectionCell={{
                checkboxIndicator: { "aria-label": "Select row" },
              }}
            >
              {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      {list.length === 0 && (
        <Body1 className="no-matching">
          No matching Tags. Consider changing your search criteria.
        </Body1>
      )}
    </Card>
  );
};
